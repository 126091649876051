import React from 'react'
import { useField } from 'formik'

import { Switch as EvergreenSwitch, SwitchProps } from 'evergreen-ui'

export interface Props extends SwitchProps {
  name: string
}

const Checkbox = ({ className, ...props }: Props) => {
  const { name, height = 24 } = props
  const [field] = useField({ name, type: 'checkbox' })

  return (
    <EvergreenSwitch
      marginBottom={16}
      height={height}
      {...props}
      checked={field.checked}
      onChange={() => field.onChange({ target: { name, value: !field.checked } })}
    />
  )
}

export default Checkbox
