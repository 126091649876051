import React from 'react'
import { useField } from 'formik'

import { TextInput as EvergreenTextInput, TextInputProps, Pane } from 'evergreen-ui'

import Icon from './icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends TextInputProps {
  name: string
  height?: number
  icon?: IconProp
}

const TextInput = ({ icon, width, flex, marginLeft, marginRight, ...props }: Props) => {
  const [field, meta] = useField(props.name)
  const { height = 48 } = props
  if (icon) props.paddingLeft = height

  return (
    <>
      <Pane
        position="relative"
        width={width}
        flex={flex}
        marginBottom={typeof props.marginBottom === 'number' ? props.marginBottom : 16}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        {icon && (
          <Icon
            icon={icon}
            position="absolute"
            size="lg"
            lineHeight={`${height}px`}
            left={`${height && height / 3}px`}
          />
        )}
        <EvergreenTextInput width="100%" height={48} {...field} {...props} isInvalid={meta.touched && !!meta.error} />
      </Pane>
    </>
  )
}

export default TextInput
