import React from 'react'
import styled from 'styled-components/macro'

import { useField } from 'formik'

import { SegmentedControl as EvergreenSegmentedControl, SegmentedControlProps } from 'evergreen-ui'

export interface Props extends SegmentedControlProps {
  name: string
}

const SegmentedControl = ({ name, ...props }: Props) => {
  const [field, meta] = useField(name)
  return (
    <EvergreenSegmentedControl
      marginBottom={16}
      value={field.value}
      onChange={value => field.onChange({ target: { name, value } })}
      {...props}
      isinvalid={meta.touched && !!meta.error ? 'true' : 'false'}
    />
  )
}

export default styled(SegmentedControl)`
  label:last-child {
    margin-bottom: 0;
  }
`
