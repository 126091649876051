import gql from 'graphql-tag'

export const GET_SERVICE_SUBSCRIPTION_LIST = gql`
query ServiceSubscriptionLists {
  ServicePlanSubscriptions {
    id
    plan {
        id
        name
    }
    account {
        id
        name
        programName
        clearentPaymentKey
    }
    additionalServiceFees{
      id
      type
      fee
    }
    startDate
    endDate
    firstBilling
    setupFee
    fee
    status
    lastPaymentDate
    isEnabled
    isCancelled
    acceptDate
    creditCard_desc
    creditCard_exp
    creditCard_lastfour
    creditCard_token
    creditCard_type
    fullName
    acceptDate
    agreementURL
    createdAt
    practice {
      practiceName
      accountId
    }
  }
}
`

export const GET_OWN_SERVICE_SUBSCRIPTION_LIST = gql`
query OwnServiceSubscriptionLists($first: Int, $after: String) {
  OwnServicePlanSubscriptions(first: $first, after: $after) {
      edges {
        cursor
        node  {
          id
          plan {
              id
              name
          }
          account {
              id
              name
              programName
              clearentPaymentKey
          }
          startDate
          endDate
          setupFee
          fee
          status
          lastPaymentDate
          isEnabled
          isCancelled
          acceptDate
          creditCard_desc
          creditCard_exp
          creditCard_lastfour
          creditCard_token
          creditCard_type
          fullName
          acceptDate
          agreementURL
          practice {
            practiceName
            accountId
            accountStatus
          }
        }
      }
      pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
`


export const GET_OWN_SERVICE_SUBSCRIPTION_SEARCH_LIST = gql`
query OwnServicePlanSubscriptionsSearch($search: String!, $orderByCol: String!, $order: String!, $first: Int, $after: String) {
  OwnServicePlanSubscriptionsSearch(search: $search, orderByCol: $orderByCol, order: $order, first: $first, after: $after) {
      edges {
        cursor
        node  {
          id
          plan {
              id
              name
          }
          account {
              id
              name
              programName
              clearentPaymentKey
          }
          startDate
          endDate
          setupFee
          fee
          status
          lastPaymentDate
          isEnabled
          isCancelled
          acceptDate
          creditCard_desc
          creditCard_exp
          creditCard_lastfour
          creditCard_token
          creditCard_type
          fullName
          acceptDate
          agreementURL
          subscriberId
          practice {
            practiceName
            accountId
            accountStatus
          }
          userVendor{
            id
            accountId
            vendorId
          }
        }
      }
      pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
`

export const UPDATE_SERVICE = gql`
  mutation ActiveServicesAgreementContract($id: ID!, $fullName: String){
    activeServicesAgreementContract(id: $id, fullName: $fullName){
      id
    plan {
        id
        name
    }
    account {
        id
        name
        programName
        clearentPaymentKey
    }
    startDate
    endDate
    setupFee
    fee
    status
    isEnabled
    isCancelled
    acceptDate
    creditCard_desc
    creditCard_exp
    creditCard_lastfour
    creditCard_token
    creditCard_type
    fullName
    acceptDate
    agreementURL
    }
  }
`

export const DELETE_SERVICE_PLAN = gql`
  mutation DeleteServicePlan($id: ID!) {
    deleteServicePlan(id: $id) {
      id
    }
  }
`
export const CANCEL_SERVICE_PLAN = gql`
  mutation CancelServicePlan($id: ID!) {
    cancelServicePlan(id: $id) {
      id
    }
  }
`
export const CONTINUE_SERVICE_PLAN = gql`
  mutation ContinueServicePlan($id: ID!) {
    continueServicePlan(id: $id) {
      id
    }
  }
`

export const ENROLL_SERVICE = gql`
  mutation EnrollService($planId: ID!, $setupFee: Float!, $usesFee: Float!,$serviceFee: Float!, $firstBilling: DateTime!) {
    enrollService(planId: $planId, setupFee: $setupFee, usesFee:$usesFee, serviceFee: $serviceFee, firstBilling: $firstBilling) {
      id
      plan {
          id
          name
      }
      account {
          id
          name
          programName
          clearentPaymentKey
      }
      startDate
      endDate
      setupFee
      fee
      status
      isEnabled
      isCancelled
      acceptDate
      creditCard_desc
      creditCard_exp
      creditCard_lastfour
      creditCard_token
      creditCard_type
      fullName
      acceptDate
      agreementURL
    }
  }
`
