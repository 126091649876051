import { library } from '@fortawesome/fontawesome-svg-core'

// SOLID
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import { faCheck as faCheck_s } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan'
import { faSortCircle } from '@fortawesome/pro-solid-svg-icons/faSortCircle'
import { faChevronUp as faChevronUp_s } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faChevronDown as faChevronDown_s } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronLeft as faChevronLeft_s } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight as faChevronRight_s } from '@fortawesome/pro-solid-svg-icons/faChevronRight'

import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint'
import { faBrowser } from '@fortawesome/pro-solid-svg-icons/faBrowser'
import { faAd } from '@fortawesome/pro-solid-svg-icons/faAd'
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload'
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode'
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload'
import { faEnvelope as faEnvelope_s } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faLifeRing } from '@fortawesome/pro-solid-svg-icons/faLifeRing'
import { faSave } from '@fortawesome/pro-solid-svg-icons/faSave'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'

// REGULAR
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronUp as faChevronUp_r } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faChevronDown as faChevronDown_r } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faCommentAltLines } from '@fortawesome/pro-regular-svg-icons/faCommentAltLines'
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign'
import { faStickyNote } from '@fortawesome/pro-regular-svg-icons/faStickyNote'

import { faIdCardAlt as faIdCardAlt_r } from '@fortawesome/pro-regular-svg-icons/faIdCardAlt'
import { faStoreAlt as faStoreAlt_r } from '@fortawesome/pro-regular-svg-icons/faStoreAlt'
import { faMapMarkedAlt as faMapMarkedAlt_r } from '@fortawesome/pro-regular-svg-icons/faMapMarkedAlt'
import { faUsers as faUsers_r } from '@fortawesome/pro-regular-svg-icons/faUsers'
import { faUniversity as faUniversity_r } from '@fortawesome/pro-regular-svg-icons/faUniversity'

// LIGHT
import { faEnvelope as faEnvelope_l } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'

// DUOTONE
import { faUnlock } from '@fortawesome/pro-duotone-svg-icons/faUnlock'
import { faFilesMedical } from '@fortawesome/pro-duotone-svg-icons/faFilesMedical'
import { faHomeLgAlt } from '@fortawesome/pro-duotone-svg-icons/faHomeLgAlt'
import { faTooth } from '@fortawesome/pro-duotone-svg-icons/faTooth'
import { faCogs } from '@fortawesome/pro-duotone-svg-icons/faCogs'
import { faBullhorn } from '@fortawesome/pro-duotone-svg-icons/faBullhorn'
import { faChartLine } from '@fortawesome/pro-duotone-svg-icons/faChartLine'
import { faCheck as faCheck_d } from '@fortawesome/pro-duotone-svg-icons/faCheck'
import { faUniversity as faUniversity_d } from '@fortawesome/pro-duotone-svg-icons/faUniversity'
import { faUsers as faUsers_d } from '@fortawesome/pro-duotone-svg-icons/faUsers'
import { faMapMarkedAlt as faMapMarkedAlt_d } from '@fortawesome/pro-duotone-svg-icons/faMapMarkedAlt'
import { faStoreAlt as faStoreAlt_d } from '@fortawesome/pro-duotone-svg-icons/faStoreAlt'
import { faIdCardAlt as faIdCardAlt_d } from '@fortawesome/pro-duotone-svg-icons/faIdCardAlt'
import { faAnalytics } from '@fortawesome/pro-duotone-svg-icons/faAnalytics'

import { faPlus as faPlus_d } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import { faRocket } from '@fortawesome/pro-duotone-svg-icons/faRocket'
import { faCommentSmile } from '@fortawesome/pro-duotone-svg-icons/faCommentSmile'
import { faUserHeadset } from '@fortawesome/pro-duotone-svg-icons/faUserHeadset'

// BRAND
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faStripeS } from '@fortawesome/free-brands-svg-icons/faStripeS'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'

export const initializeIconLibrary = () => {
  library.add(
    faGoogle,
    faFacebookSquare,
    faLinkedin,
    faUnlock,
    faChevronDown_r,
    faChevronDown_s,
    faFilesMedical,
    faUserPlus,
    faPaperPlane,
    faCheckCircle,
    faUserCircle,
    faCommentAltLines,
    faHomeLgAlt,
    faTooth,
    faCogs,
    faBullhorn,
    faChartLine,
    faChevronLeft,
    faChevronRight,
    faStripeS,
    faCheck_d,
    faEnvelope_l,
    faLock,
    faSearch,
    faPencilAlt,
    faTrashAlt,
    faCheck_s,
    faDollarSign,
    faStickyNote,
    faPrint,
    faChevronUp_r,
    faChevronUp_s,
    faPlus_d,
    faRocket,
    faCommentSmile,
    faUserHeadset,
    faTwitter,
    faBrowser,
    faAd,
    faUpload,
    faCode,
    faFileDownload,
    faEnvelope_s,
    faPhone,
    faIdCardAlt_r,
    faStoreAlt_r,
    faMapMarkedAlt_r,
    faUsers_r,
    faUniversity_r,
    faIdCardAlt_d,
    faStoreAlt_d,
    faMapMarkedAlt_d,
    faUsers_d,
    faUniversity_d,
    faFilePlus,
    faPlus,
    faLifeRing,
    faSave,
    faEdit,
    faSortCircle,
    faBan,
    faAnalytics,
    faChevronLeft_s,
    faChevronRight_s
  )
}
