import gql from 'graphql-tag'

export const GET_PLAN_CARD_LIST = gql`
  query PlanCardList($accountId: ID, $age: Int, $perio: Boolean) {
    livePlans(accountId: $accountId, age: $age, perio: $perio) {
      id
      name
      perio
      minAge
      maxAge
      annualPriceActive
      annualPrice
      annualDependentPrice
      monthlyPriceActive
      monthlyPrice
      monthlyDependentPrice
      dependentDiscount
      globalDiscountActive
      globalDiscount
      treatments {
        id
        name
        quantity
      }
      discounts {
        id
      }
    }
  }
`
