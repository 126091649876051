import React, { useContext, createContext } from 'react'
import { injectStripe, StripeProvider, Elements, ReactStripeElements } from 'react-stripe-elements'

const Context = createContext<ReactStripeElements.InjectedStripeProps | undefined>(undefined)

export const useStripe = () => useContext(Context)

type HookProvider = (
  Props: {
    children: React.ReactNode
  } & ReactStripeElements.InjectedStripeProps
) => any

const HookProvider: HookProvider = ({ children, ...injectedStripeProps }) => {
  return <Context.Provider value={injectedStripeProps}>{children}</Context.Provider>
}

const InjectedHookProvider = injectStripe(HookProvider)

export const StripeElementsProvider = ({ apiKey, children }: { apiKey: string; children: React.ReactNode }) => (
  <StripeProvider apiKey={apiKey}>
    <Elements>
      <InjectedHookProvider>{children}</InjectedHookProvider>
    </Elements>
  </StripeProvider>
)
