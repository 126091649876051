import gql from 'graphql-tag'

export const GET_NEWSLETTERS = gql`
query GetNewsletters($search:String!, $startDate:String, $endDate:String, $first: Int, $after: String) {
  getNewsletters(search:$search, startDate:$startDate, endDate:$endDate, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        to
        subject
        body
        status
        files
        remarks
        sentDate
        createdAt
      }
    }
    pageInfo {
      endCursor 
      hasNextPage
    }
  }
}
`

export const SEND_NEWSLETTER_EMAIL = gql`
    mutation SendNewsletterEmail(
      $id: ID,
      $to: String!,
      $subject: String!,
      $body: String!,
      $status: String!,
      $files: [EmailAttachmentInput!]
    ) {
    sendNewsletterEmail(
      id:$id,
      to:$to,
      subject:$subject,
      body:$body,
      status:$status,
      files:$files
    ) {
      id
      to
      subject
      body
      files
      status
      createdAt
      sentDate
  }
}
`
