import React, { useState } from 'react'
import * as Types from '../types'
import styled from 'styled-components/macro'
import moment from 'moment'

import { ApolloClient } from 'apollo-client'
// import { useQuery } from '@apollo/react-hooks'

// import { GET_CONTRACT_SHEET } from '../graphql/_contract-sheet'

import { SideSheet, Pane, Label, Heading, Button, Text } from 'evergreen-ui'
import FormError from '../form-error'

import CardHeader from '../card-header'
// import Spinner from '../spinner'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import TextInputField from '../text-input-field'


// import { effectiveDateRangeInDateTime } from '../utility/formatters'
import { colors } from '../utility/theme'
import { useMediaQueryContext } from '../media-query-provider'
// import { UPDATE_START_END_DATE } from '../graphql/_view-member-sheet'
// import { useMutation } from '@apollo/react-hooks'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  subsId: string
  planName: string
  memberName?: string
  isDependent?:boolean
  effectiveDate?: any
  endDate?: any
  planTerm?: Types.PlanTerm
  cancelled?: boolean
  apolloClient?: ApolloClient<any>,
  udpateDate: [Types.UpdateStartEndDate, Types.UpdateStartEndDateVariables],
}



const UpdatePlanDateSheet = ({
  isShown,
  setIsShown,
  subsId,
  effectiveDate,
  endDate,
  // apolloClient,
  planName,
  memberName,
  isDependent,
  udpateDate: [updateDate],
}: Props) => {
  const [dirty, setDirty] = useState(false);
  const { isMobile } = useMediaQueryContext()
  const startDateUtc = moment.utc(effectiveDate)
  const endDateUtc = moment.utc(endDate)
  // const [update] = useMutation<Types.updateStartEndDate, Types.updateStartEndDateVariables>(UPDATE_START_END_DATE)
  const initialValues = {
    startDateMonth: startDateUtc.format('M'),
    startDateDay: startDateUtc.format('D'),
    startDateYear: startDateUtc.format('YYYY'),
    endDateMonth: endDateUtc.format('M'),
    endDateDay: endDateUtc.format('D'),
    endDateYear: endDateUtc.format('YYYY'),
  }
  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={600}
      shouldCloseOnOverlayClick= {false}
      preventBodyScrolling={true}
      //@ts-ignore
      position={isMobile ? 'bottom' : 'right'}
    >

      <Formik initialValues={{
        startDateMonth: startDateUtc.format('M'),
        startDateDay: startDateUtc.format('D'),
        startDateYear: startDateUtc.format('YYYY'),
        endDateMonth: endDateUtc.format('M'),
        endDateDay: endDateUtc.format('D'),
        endDateYear: endDateUtc.format('YYYY'),
      }}
        validate={args => {
          if (!dirty && args !== initialValues) setDirty(true)
        }}
        onSubmit={args => {
          // const stDate = moment.utc(args.startDateYear + '/' + args.startDateMonth + '/' + args.startDateDay);
          // const edDate = moment.utc(args.endDateYear + '/' + args.endDateMonth + '/' + args.endDateDay);

          // if (!stDate.isValid()) {
          //   toaster.danger(`Start Date is Invalid`)
          //   return;
          // }
          // if (!edDate.isValid()) {
          //   toaster.danger(`End Date is Invalid`)
          //   return;
          // }

          updateDate({
            variables: {
              id: subsId,
              startDate: moment.utc(args.startDateYear + '/' + args.startDateMonth + '/' + args.startDateDay),
              endDate: moment.utc(args.endDateYear + '/' + args.endDateMonth + '/' + args.endDateDay)
            }
          })
        }
        }
        validationSchema={Yup.object({
          startDateMonth: Yup.string().required('Start Date Month is required'),
          startDateDay: Yup.string().required('start Date Day is required'),
          startDateYear: Yup.string().required('Start Date Year is required'),
          endDateMonth: Yup.string().required('End Date Month is required'),
          endDateDay: Yup.string().required('End Date Day is required'),
          endDateYear: Yup.number().required('End Date Year is required')
          // .integer('Please provide a valid year')
          // .min(moment().year(), 'Please provide a valid year')
          // .max(moment().year(), 'Please provide a valid year'),
        })}
      >
        <Form style={{ height: '100%', width: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
          <SheetLayout>
            <Pane>
              <CardHeader gridArea="header">
                <Heading size={600}>{planName}</Heading>
              </CardHeader>

            </Pane>
            <Pane gridArea="body" overflow="scroll" height={isMobile ? 'calc(100vh - 112px)' : '100%'}>
              <Pane margin={24}>
                <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                  Update Effective Dates
                    </Heading>

                <Label alignSelf="flex-start">{isDependent ? 'Dependent' : 'Member'} Name</Label>
                <Pane display="flex" marginBottom={20}>
                  <Text size={600}>{memberName}</Text>
                </Pane>
                <Label alignSelf="flex-start">Start Dates</Label>
                <Pane display="flex">
                  <TextInputField
                    name="startDateMonth"
                    flex={1}
                    placeholder="MM"
                    height={40}
                    marginRight={16}
                    textAlign="center"
                    hint="Month"
                  />
                  <TextInputField
                    name="startDateDay"
                    flex={1}
                    placeholder="DD"
                    height={40}
                    marginRight={16}
                    textAlign="center"
                    hint="Day"
                  />
                  <TextInputField name="startDateYear" flex={2} placeholder="YYYY" textAlign="center" height={40}
                    hint="Year"
                  />
                </Pane>

                <Label alignSelf="flex-start">End Date</Label>
                <Pane display="flex">
                  <TextInputField
                    name="endDateMonth"
                    flex={1}
                    placeholder="MM"
                    height={40}
                    marginRight={16}
                    textAlign="center"
                    hint="Month"
                  />
                  <TextInputField
                    name="endDateDay"
                    flex={1}
                    placeholder="DD"
                    height={40}
                    marginRight={16}
                    textAlign="center"
                    hint="Day"
                  />
                  <TextInputField name="endDateYear" flex={2} placeholder="YYYY" textAlign="center" height={40}
                    hint="Year"
                  />
                </Pane>
                <FormError marginLeft={0} marginTop={0} max={2} />
                <Pane display="flex" justifyContent="flex-start">
                  <Button
                    type="submit"
                    marginRight={8} >
                    Update
                      </Button>

                </Pane>
              </Pane>
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default UpdatePlanDateSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
`
