import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import * as Types from '../types'
import moment from 'moment'

import { useMutation } from '@apollo/react-hooks'
import { UPDATE_CONTACT_PROFILE } from '../graphql/secure-operations'
import { UPDATE_CONTACT_IDENTITY } from '../graphql/_view-member-sheet'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, toaster } from 'evergreen-ui'
import Button from '../button'
import Card from '../card'
import FormError from '../form-error'
import MemberFields from '../_fields/member-fields'
import ConfirmDialog from '../_dialogs/confirm-dialog'
import { VgsWrap } from '../utility/vgs-utils'

export type Props = {
  contact: Types.ViewMemberSheet_contact
  secureContact: Types.SecureContact,
  isAdmin: boolean
}

const ViewMemberProfile = ({ contact, secureContact }: Props) => {
  const [isConfirmDialogShown, setIsConfirmDialogShown] = useState(false)
  const [confirmFunction, setConfirmFunction] = useState({} as any)

  const [updateProfile, updateProfileStatus] = useMutation<
    Types.UpdateContactProfile,
    Types.UpdateContactProfileVariables
  >(UPDATE_CONTACT_PROFILE, {
    context: { secure: true }
  })

  const [updateIdentity, updateIdentityStatus] = useMutation<
    Types.UpdateContactIdentity,
    Types.UpdateContactIdentityVariables
  >(UPDATE_CONTACT_IDENTITY)

  useEffect(() => {
    if (!updateProfileStatus.loading && updateProfileStatus.data) {
      toaster.success(`Profile successfully updated!`)
    }
  }, [updateProfileStatus])

  return (
    <>
      {/* SaveConfirm */}
      <ConfirmDialog
        isShown={isConfirmDialogShown}
        setIsShown={setIsConfirmDialogShown}
        confirm={confirmFunction}
        body="Are you sure you want to update this member's profile?"
      />
      <Formik
        initialValues={{
          firstName: secureContact.firstName,
          lastName: secureContact.lastName,
          email: secureContact.email,
          phone: secureContact.phone,
          dobMonth: secureContact.dobMonth,
          dobDay: secureContact.dobDay,
          dobYear: secureContact.dobYear,
          perio: secureContact.perio,
          address1: secureContact.address1 ?? '',
          address2: secureContact.address2 ?? '',
          city: secureContact.city ?? '',
          state: secureContact.state ?? ('' as Types.State),
          zip: secureContact.zip ?? ''
        }}
        onSubmit={fields => {
          setConfirmFunction(() => async () => {
            try {
              if(!contact.parent){
                if (
                  fields.email !== secureContact.email ||
                  fields.phone !== secureContact.phone ||
                  fields.firstName !== secureContact.firstName ||
                  fields.lastName !== secureContact.lastName
                ) {
                  await updateIdentity({
                    variables: {
                      currentEmail: secureContact.email ?? '',
                      newEmail: fields.email ?? '',
                      phone: fields.phone ?? '',
                      firstName: fields.firstName,
                      lastName: fields.lastName
                    }
                  })
                }
              }
              await updateProfile({
                variables: {
                  id: contact.id,
                  secure: VgsWrap<Types.SecureContact>(fields)
                }
              })
            } catch (err) {
              toaster.danger(err.message.replace('GraphQL error: ', ''))
            }
          })
          setIsConfirmDialogShown(true)
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: contact.parent ?
            Yup.string()
              .email('Please enter a valid email') :
            Yup.string()
              .required('Email is required')
              .email('Please enter a valid email'),
          phone: contact.parent ?
            Yup.string()
              .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number') :
            Yup.string()
              .required('Phone is required')
              .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number'),
          dobMonth: Yup.number()
            .required('Birth month is required')
            .integer('Please provide a valid birth month')
            .min(1, 'Please provide a valid birth month')
            .max(12, 'Please provide a valid birth month'),
          dobDay: Yup.number()
            .required('Birth day is required')
            .integer('Please provide a valid birth day')
            .min(1, 'Please provide a valid birth day')
            .max(32, 'Please provide a valid birth day'),
          dobYear: Yup.number()
            .required('Birth year is required')
            .integer('Please provide a valid birth year')
            .min(1900, 'Please provide a valid birth year')
            .max(moment().year(), 'Please provide a valid birth year'),
          perio: Yup.boolean(),
          address1: Yup.string().when('address2', {
            is: val => val,
            then: Yup.string().required('Please submit a complete address')
          }),
          address2: Yup.string(),
          state: Yup.mixed().oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation / capital)'),
          zip: Yup.string().matches(/^[0-9]\d{4}$/, 'Please enter a valid zip code (5 digits)')
        })}
      >
        <Form style={{ height: '100%', width: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
          <SheetBodyLayout>
            <Pane gridArea="body" background="blueTint" overflow="scroll">
              <Card backgroundColor="white" elevation={0} margin={16}>
                <MemberFields />
              </Card>
            </Pane>
            <Pane
              gridArea="footer"
              elevation={0}
              padding={16}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormError max={2} />
              <Button
                type="submit"
                appearance="primary"
                height={48}
                justifyContent="center"
                isLoading={updateProfileStatus.loading || updateIdentityStatus.loading}
              >
                Save
              </Button>
            </Pane>
          </SheetBodyLayout>
        </Form>
      </Formik>
    </>
  )
}

export default ViewMemberProfile

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
