import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import * as Types from 'types'

import { Switch, Route, useLocation, useHistory, Redirect } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import { Pane } from 'evergreen-ui'

import Onboarding from 'components/onboarding'

import { FullScreen, Link, Button, useMediaQueryContext, ProgressBar, useToken } from '@pearly/lib'

import logo from 'assets/img/Asset 5.png'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = {
  className?: string
}

const steps: {
  name: Types.OnboardingStep
  path: string
  component: React.FC<{
    prev: string
    next: string
    setIsForward: React.Dispatch<React.SetStateAction<boolean>>
  }>
  icon: IconProp
}[] = [
    { name: Types.OnboardingStep.START, path: '/onboarding', component: Onboarding.Start, icon: ['far', 'id-card-alt'] },
    {
      name: Types.OnboardingStep.PRACTICE,
      path: '/onboarding/practice',
      component: Onboarding.Practice,
      icon: ['far', 'store-alt']
    },
    {
      name: Types.OnboardingStep.OFFICES,
      path: '/onboarding/offices',
      component: Onboarding.Offices,
      icon: ['far', 'map-marked-alt']
    },
    {
      name: Types.OnboardingStep.DENTISTS,
      path: '/onboarding/dentists',
      component: Onboarding.Dentists,
      icon: ['far', 'users']
    },
    //{
    //  name: Types.OnboardingStep.BANK,
    //  path: '/onboarding/bank',
    //  component: Onboarding.Bank,
    //  icon: ['far', 'university']
    //},
    {
      name: Types.OnboardingStep.COMPLETE,
      path: '/onboarding/complete',
      component: Onboarding.Complete,
      icon: ['far', 'university']
    }
  ]

const OnboardingView = ({ className }: Props) => {
  const { tokenResult } = useToken()
  const history = useHistory()

  const location = useLocation()
  const { pathname } = location
  const { isMobile } = useMediaQueryContext()

  const [isForward, setIsForward] = useState(true)
  const exitAnimationDuration = 0.15

  const currentStepIndex = steps.findIndex(step => step.path === pathname)

  // Redirect onboarding complete and restrict onboarding step to furthest step reached
  useEffect(() => {

    if (tokenResult) {
      const { claims } = tokenResult
      if (claims.onboardingStep === Types.OnboardingStep.COMPLETE) {
        history.push('/')
      } else {
        const latestIndex = steps.findIndex(step => step.name === claims.onboardingStep)
        if (latestIndex > -1 && currentStepIndex > latestIndex) {
          setIsForward(false)
          history.replace(steps[latestIndex].path)
        }
      }
    }
  }, [tokenResult, currentStepIndex, history])


  useEffect(() => {
    // Was unable to implement using refs because onboarding body is being unmounted / mounted
    const timeout = setTimeout(
      () => document.querySelectorAll('#onboarding-body').forEach(el => el.scrollTo({ top: 0, behavior: 'auto' })),
      exitAnimationDuration * 1000
    )
    return () => clearTimeout(timeout)
  }, [pathname])

  const variants = {
    initial: {
      opacity: 0,
      x: isMobile ? 0 : isForward ? 180 : -180
    },
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: 0.05
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: exitAnimationDuration
      }
    }
  }

  // Redirect new onboardings to first step
  if (tokenResult === null && currentStepIndex > 0) return <Redirect to="/onboarding" />
  // Redirect to dashboard if onboarding complete

  return (
    <FullScreen className={className}>
      <Pane gridArea="header" display="flex" justifyContent="space-between" alignItems="center">
        <Pane width={isMobile ? '120px' : '180px'} textAlign="center" paddingY={16}>
          <img src={logo} alt="logo" height={isMobile ? '28px' : '37px'} />
        </Pane>
        <Pane height={isMobile ? '100%' : '50px'} marginRight={8} display="flex" alignItems="center">
          <Link href="tel:8007722160">
            <Button appearance="minimal" iconBefore={['fas', 'phone']}>
              (800) 772-2160
            </Button>
          </Link>
          <Link href="mailto:support@trubludirect.com" hidden={isMobile} marginLeft={8}>
            <Button appearance="minimal" iconBefore={['fas', 'envelope']}>
              support@trubludirect.com
            </Button>
          </Link>
        </Pane>
      </Pane>
      <Pane
        gridArea="progress-bar"
        justifySelf="center"
        padding={8}
        paddingBottom={32}
        display={isMobile ? 'none' : 'block'}
      >
        <ProgressBar
          index={currentStepIndex}
          steps={steps.slice(0, -1).map(step => ({ icon: step.icon as IconProp }))}
        />
      </Pane>
      <Pane
        id="onboarding-body"
        gridArea="body"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        overflow="scroll"
      >
        <Pane
          width="400px"
          maxWidth="100vw"
          paddingX={16}
          paddingTop={isMobile ? 8 : 0}
          paddingBottom={isMobile ? 128 : 16}
          display="flex"
          justifyContent="center"
        >
          <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div
              key={pathname}
              variants={variants}
              initial="initial"
              animate="enter"
              exit="exit"
              style={{ width: '100%' }}
            >
              <Switch location={location}>
                {steps.map((step, i) => (
                  <Route key={i} exact path={step.path} strict>
                    <step.component prev={steps[i - 1]?.path} next={steps[i + 1]?.path} setIsForward={setIsForward} />
                  </Route>
                ))}
                <Redirect from="/onboarding*" to="/onboarding" />
              </Switch>
            </motion.div>
          </AnimatePresence>
        </Pane>
      </Pane>
    </FullScreen>
  )
}

export default styled(OnboardingView)`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 100%;
  height: 100vh;
  grid-template-areas:
    'header'
    'progress-bar'
    'body';
`
