import React from 'react'
import { useField } from 'formik'

import { Label, Textarea as EvergreenTextarea, TextareaProps } from 'evergreen-ui'

export interface Props extends TextareaProps {
  name: string
}

const Textarea = ({ ...props }: Props) => {
  const [field, meta] = useField(props.name)
  return (
    <>
      <Label htmlFor={props.id} marginBottom={4} display="block">
        {props.label}
      </Label>
      <EvergreenTextarea id={props.id} marginBottom={16} padding={13} {...field} {...props} isInvalid={meta.touched && !!meta.error} />
    </>
  )
}

export default Textarea
