import gql from 'graphql-tag';

const fragments = {
  // also refactor planGridFields
  pdUserFields: gql`
      fragment pdUserFields on PDUser {
        id
        account {
          id
          code
          name
        }
        accountId
        isNew
        PDAccountNumber
        PDAccountNumberReceived
        NPI
        PDAccountName
        billingAddress
        billingCity
        billingState
        billingZip
        PDPrimaryContactName
        PDprimaryContactNumber
        createdAt
        activatedAt
        cancelAt
        lastUpdated
        status
        sentToPD
        agreementURL
      }
    `
}

export const GET_MY_PD_ACCOUNT_TABLE = gql`
query PDAccountTable($isNew: Boolean!, $search: String) {
  PDAccountTable(isNew: $isNew, search: $search) {
    ...pdUserFields
  }
}
${fragments.pdUserFields}
`
export const GET_MY_PD_ACCOUNT = gql`
query GetMyPDAccount {
  myPDAccount {
        id
        account {
          id
          code
          name
        }
        accountId
        isNew
        PDAccountNumber
        PDAccountNumberReceived
        NPI
        PDAccountName
        billingAddress
        billingCity
        billingState
        billingZip
        PDPrimaryContactName
        PDprimaryContactNumber
        createdAt
        cancelAt
        activatedAt
        lastUpdated
        status
        sentToPD
        agreementURL
        vendorSubscriptions {
          status
          createdAt
          startDate
          endDate
          fee
          nextPaymentDate
          firstBilling
          acceptDate
        }
  }
}
`

export const DELETE_PD_Account = gql`
  mutation DeletePDAccount($id: ID!) {
    deletePDAccount(id: $id) {
      id
    }
  }
`
export const ACTIVATE_PD_Account = gql`
  mutation ActivatePDAccount($id: ID!,$vendorId:ID!) {
    activatePDAccount(id: $id,vendorId:$vendorId) {
    ...pdUserFields
    }
  }
  ${fragments.pdUserFields}
`
export const INACTIVATE_PD_Account = gql`
mutation InactivatePDAccount($id: ID!) {
  inactivatePDAccount(id: $id) {
    ...pdUserFields
    }
  }
  ${fragments.pdUserFields}
`
export const Cancelled_PD_Account = gql`
mutation cancelledPDAccount($id: ID!,$vendorId:ID!) {
  cancelledPDAccount(id: $id,vendorId:$vendorId) {
    ...pdUserFields
    }
  }
  ${fragments.pdUserFields}
`