import React from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery } from '@apollo/react-hooks'
import { GET_ONBOARDING_OFFICES } from 'graphql/_onboarding'
import * as Types from 'types/graphql'

import { Pane, Text, Heading, Pill } from 'evergreen-ui'

import { Card, CardHeader, Icon, Button, OfficeCard, Spinner } from '@pearly/lib'

import { useModal } from 'components/modal-provider'

export type Props = {
  prev: string
  next: string
  setIsForward: React.Dispatch<React.SetStateAction<boolean>>
}

const OnboardingOffices = ({ prev, next, setIsForward }: Props) => {
  const history = useHistory()
  const showOfficeSheet = useModal('office')
  const { loading, error, data } = useQuery<Types.OnboardingOffices>(GET_ONBOARDING_OFFICES)

  return loading || error || !data ? (
    <Spinner paddingTop={0} delay={1200} />
  ) : (
    <Card padding={0}>
      <CardHeader>
        <Heading size={500} flexGrow={1} textAlign="center">
          Set up your Practice
        </Heading>
        <Pill color="blue">3/4</Pill>
      </CardHeader>

      <Pane padding={24} display="flex" flexDirection="column" alignItems="center">
        <Icon icon={['fad', 'map-marked-alt']} color="primary" size="3x" marginBottom={16} />
        <Text size={500} marginBottom={24}>
          Your Office
        </Text>
        {data.offices.map((office, i) => (
          <OfficeCard
            office={office}
            key={i}
            width="100%"
            marginBottom={8}
            cursor="pointer"
            onClick={() => showOfficeSheet({ office })}
          />
        ))}
        {/* <Button height={48} marginTop={16} iconBefore={['fas', 'plus']} onClick={() => showOfficeSheet()}>
          Add Another Office
        </Button> */}
      </Pane>

      <Pane zIndex={1} elevation={0} padding={16} flexShrink="0" display="flex" justifyContent="space-between">
        <Button
          appearance="minimal"
          height={48}
          justifyContent="center"
          iconBefore={['far', 'chevron-left']}
          onClick={() => {
            setIsForward(false)
            history.push(prev)
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setIsForward(true)
            history.push(next)
          }}
          appearance="primary"
          height={48}
          justifyContent="center"
          iconAfter={['far', 'chevron-right']}
        >
          Next
        </Button>
      </Pane>
    </Card>
  )
}

export default OnboardingOffices
