import gql from 'graphql-tag'

const fragments = {
  officeFields: gql`
    fragment officeFields on Office {
      id
      name
      address1
      address2
      city
      state
      zip
      phone
      email
    }
  `
}

export const UPSERT_OFFICE = gql`
  mutation UpsertOffice(
    $id: ID
    $name: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: State!
    $zip: String!
    $phone: String!
    $email: String
  ) {
    upsertOffice(
      id: $id
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      email: $email
    ) {
      ...officeFields
    }
  }
  ${fragments.officeFields}
`

export const DELETE_OFFICE = gql`
  mutation DeleteOffice($id: ID!) {
    deleteOffice(id: $id) {
      id
    }
  }
`

export const GET_OFFICES = gql`
  query GetOffices {
    offices {
      ...officeFields
    }
  }
  ${fragments.officeFields}
`
