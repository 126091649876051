import React, { useState, useContext, createContext, useCallback, useEffect } from 'react'

import { useUser } from 'reactfire'

export type Props = {
  children: React.ReactNode
}

type Context = {
  tokenResult?: firebase.auth.IdTokenResult | null
  refreshToken: () => void
}

const Context = createContext({} as Context)
export const useToken = () => useContext(Context)

export const TokenProvider = ({ children }: Props) => {
  const user = useUser<firebase.User>()
  const [tokenResult, setTokenResult] = useState(undefined as firebase.auth.IdTokenResult | null | undefined)

  const setTokenResultFromFirebase = async (user: firebase.User, message: string) => {
    if (!user) return setTokenResult(null)
    const tokenResult = await user.getIdTokenResult(true)
    setTokenResult(tokenResult)

    console.log(message)
    console.log(tokenResult)
  }

  useEffect(() => {
    setTokenResultFromFirebase(user, 'Token Initialized')
  }, [user])

  const refreshToken = useCallback(async () => {
    await setTokenResultFromFirebase(user, 'Token Refreshed')
  }, [user])

  return <Context.Provider value={{ tokenResult, refreshToken }}>{children}</Context.Provider>
}
