import React from 'react'
import { useField } from 'formik'

import { TextInputField as EvergreenTextInputField, TextInputFieldProps } from 'evergreen-ui'

export interface Props extends Omit<TextInputFieldProps, 'label'> {
  name: string
  height?: number
  label?: string
}

const TextInputField = ({ height = 48, label = '', ...props }: Props) => {
  const [field, meta] = useField(props.name)
  return (
    <EvergreenTextInputField
      marginBottom={16}
      inputHeight={height}
      style={{ textAlign: props.textAlign }}
      label={label}
      {...field}
      {...props}
      isInvalid={meta.touched && !!meta.error}
    />
  )
}

export default TextInputField
