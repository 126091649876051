import gql from 'graphql-tag'

export const GET_DEPENDENT_CARD_LIST = gql`
  query DependentCardList($contactId: ID!) {
    dependents(contactId: $contactId) {
      id
      code
      secure
      planTerm
      subscriptions {
        id
        startDate
        endDate
        isEnabled
        isCancelled
        status 
        creditCard_type
        plan {
          id
          name
          dependentDiscount
          annualDependentPrice
          monthlyDependentPrice
        }
        planId 
        planTerm
        collectingMethod  
        renewalSubscriptionId    
      }
    }
  }
`
