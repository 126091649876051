import gql from 'graphql-tag'

export const CREATE_SERVICE_INVOICE_ITEM = gql`
    mutation CreateServiceInvoiceItem(
            $description: String!,
            $quantity: Int!,
            $unitPrice: Float!,
            $serviceInvoiceId: ID!,
    ){
        createServiceInvoiceItem(
            description: $description,
            quantity: $quantity,
            unitPrice: $unitPrice,
            serviceInvoiceId: $serviceInvoiceId
        ){
            id
            description
            quantity
            unitPrice
            serviceInvoiceId
        }
    }
`

export const SERVICE_INVOICE_ITEM_BY_SERVICE_INVOICE_ID = gql`
    query ServiceInvoiceItemByServiceInvoiceId($id: ID!){
        serviceInvoiceItemsByServiceInvoiceId(serviceInvoiceId: $id) {
            id
            description
            quantity
            unitPrice
            serviceInvoiceId
        }
    }
`