import gql from 'graphql-tag'

export const GET_VIEW_MEMBER_SHEET = gql`
  query ViewMemberSheet($id: ID!) {
    contact(id: $id) {
      id
      code
      secure
      account {
        clearentPaymentKey
      }
      planTerm
      plan {
        id
        name
        status
        annualPrice
        annualDependentPrice
        monthlyPrice
        monthlyDependentPrice
        dependentDiscount
      }
      subscriptions {
        id
        creditCard_token
        creditCard_type
        creditCard_lastfour
        creditCard_exp
        creditCard_desc
        startDate
        endDate
        isEnabled
        isCancelled
        status
        collectingMethod
        renewalSubscriptionId
      }
      parent {
        id
      }
    }
  }
`

export const GET_VIEW_MEMBER_SHEET_ACTIVITY = gql`
  query ViewMemberSheetActivity($id: ID!) {
    contact(id: $id) {
      id
      secureNotes
      stripe {
        charges {
          amount
          createdAt
          status
          last4
          plan
        }
      }
    }
  }
`

export const GET_VIEW_MEMBER_CONTACT_PAYMENT_AUDIT_LOG = gql`
  query ViewMemberContactPaymentAuditLog($contactId: String!) {
    PaymentAuditLogsForContact (contactId: $contactId) {
        accountId
        contactId
        createdAt
        id
        paymentEventId
        type
        user
        log
      }
  }
`


export const MEMBER_BILLING_HISTORY = gql`
  query memberBillingHistory($contactId: String!) {
    memberBillingHistory (contactId: $contactId) {
        amount
        createdAt
        id
        last4
        cardExp
        plan
        status
        isDependent
        paymentDate
        paymentNote
        sequenceNumber
        collectionMethod
        invoiceNumber
        paymentErrorMessage
    }
  }
`

export const UPDATE_CONTACT_SUBSCRIPTION = gql`
  mutation UpdateContactSubscription($id: ID!, $cancel: Boolean, $reactivate: Boolean, $accept: Boolean, $isManual: Boolean) {
    updateContactSubscription(id: $id, cancel: $cancel, reactivate: $reactivate, accept: $accept, isManual: $isManual,) {
      id
      isCancelled
      isEnabled
      autoRenewal
      status
    }
  }
`

export const GET_CONTACT_PLAN_SUBSCRIPTION_FULL = gql`
  query GetContactPlanSubscriptionsFull($id: ID!
   ) {
    getContactPlanSubscriptions(id: $id ) {
      id
      accountId
      fee
      startDate
      endDate
      planTerm
      status      
      isCancelled
      isUpComing
      isCurrent
      isArchived
      plan {
        id
        name
        annualPrice
        annualPriceActive
        monthlyPrice
        monthlyPriceActive
        status
        monthlyDependentPrice
        annualDependentPrice
        dependentDiscount
      }
  }
  }
`


export const UPDATE_CONTACT_IDENTITY = gql`
  mutation UpdateContactIdentity(
    $currentEmail: String!
    $newEmail: String!
    $phone: String!
    $firstName: String!
    $lastName: String!
  ) {
    updateContactIdentity(
      currentEmail: $currentEmail
      newEmail: $newEmail
      phone: $phone
      firstName: $firstName
      lastName: $lastName
    )
  }
`

export const UPDATE_CONTACT_PLAN_SUBSCRIPTION_CARD = gql`
  mutation UpdateContactPlanSubscriptionCard($id: ID!
    $creditCard_token: String!
    $creditCard_type: String!
    $creditCard_desc: String!
    $creditCard_lastfour: String!
    $creditCard_exp: String!
   ) {
    updateContactPlanSubscriptionCard(id: $id,  creditCard_token: $creditCard_token, creditCard_type: $creditCard_type,
      creditCard_desc: $creditCard_desc, creditCard_lastfour: $creditCard_lastfour, creditCard_exp: $creditCard_exp
    ) {
      id
    }
  }
`

export const CHANGE_CONTACT_PLAN_SUBSCRIPTION_PLAN = gql`
  mutation changeContactPlanSubscriptionPlan($id: ID!
    $planId: ID!
    $planTerm: PlanTerm!
   ) {
    changeContactPlanSubscriptionPlan(id: $id,  planId: $planId, planTerm: $planTerm      
    ) {
      id
      accountId
      fee
      plan {
        id
        name
        annualPrice
        annualPriceActive
        monthlyPrice
        monthlyPriceActive
        status
        monthlyDependentPrice
        annualDependentPrice
        dependentDiscount
      }
  }
  }
`

export const RENEW_CONTACT_PLAN_SUBSCRIPTION = gql`
  mutation renewContactPlanSubscription($id: ID!
    $planId: ID!
    $planTerm: PlanTerm!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
   ) {
    renewContactPlanSubscription(id: $id,  
    planId: $planId, 
    planTerm: $planTerm,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    phoneNumber: $phoneNumber
    ) {
      id
      accountId
      fee
      plan {
        id
        name
        annualPrice
        annualPriceActive
        monthlyPrice
        monthlyPriceActive
        status
        monthlyDependentPrice
        annualDependentPrice
        dependentDiscount
      }
  }
  }
`


export const CLONE_CONTACT_PLAN_SUBSCRIPTION = gql`
  mutation cloneContactPlanSubscription($id: ID!
    $planId: ID!
    $planTerm: PlanTerm!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
   ) {
    cloneContactPlanSubscription(id: $id,  planId: $planId, planTerm: $planTerm ,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    phoneNumber: $phoneNumber     
    ) {
      id
      accountId
      fee
      plan {
        id
        name
        annualPrice
        annualPriceActive
        monthlyPrice
        monthlyPriceActive
        status
        monthlyDependentPrice
        annualDependentPrice
        dependentDiscount
      }
  }
  }
`

export const UPDATE_START_END_DATE = gql`
  mutation updateStartEndDate (
    $endDate: DateTime, 
    $id: ID, 
    $startDate: DateTime) {
    updateStartEndDate (
          endDate: $endDate,
          id: $id,
          startDate: $startDate) {
        accountId
        autoRenewal
        contactId
        createdAt
        creditCard_desc
        creditCard_exp
        creditCard_lastfour
        creditCard_token
        creditCard_type
        endDate
        fee
        id
        isCancelled
        isEnabled
        lastPaymentDate
        lastUpdated
        nextIteration
        nextPaymentDate
        planId
        startDate
        totalCollected
    }
  }
`

export const CREATE_PLAN_PAYMENT_EVENT = gql`
mutation CreateContactPaymentEvent(
  $contactId: ID!,
  $subscriptionId: ID!,
  $creditCard_lastfour: String,
  $creditCard_transStatus: String,
  $paymentAmount: Float!,
  $creditCard_type: String,
  $type: paymentType!,
  $paymentStatus: paymentStatus!,
  $creditCard_message: String,
  $creditCard_exp: String,
  $paymentDate: String!,
  $paymentNote: String
){
  createContactPaymentEvent(
    contactId: $contactId,
    subscriptionId: $subscriptionId,
    creditCard_lastfour: $creditCard_lastfour,
    creditCard_transStatus: $creditCard_transStatus,
    paymentAmount: $paymentAmount,
    creditCard_type: $creditCard_type,
    type: $type,
    paymentStatus: $paymentStatus,
    creditCard_message: $creditCard_message,
    creditCard_exp: $creditCard_exp,
    paymentDate: $paymentDate,
    paymentNote: $paymentNote
  ){
      id
  }
}
`
