import React from 'react'
import styled from 'styled-components/macro'
import * as Types from '../types'

import { Pane } from 'evergreen-ui'

import DependentCardList from '../dependent-card-list'

export type Props = {
  contact: Types.ViewMemberSheet_contact,
  accountToken?: any
}

const ViewMemberProfile = ({ contact, accountToken }: Props) => (
  <SheetBodyLayout>
    <Pane gridArea="body" background="blueTint" overflow="scroll">
      <DependentCardList  isAdmin={true} contactId={contact.id} accountToken={accountToken} inSheet height="100%" />
    </Pane>
  </SheetBodyLayout>
)

export default ViewMemberProfile

const SheetBodyLayout = styled.div`
  height: 100%;
  overflow-x: auto;
  grid-template-rows: auto;

  grid-template-areas: 'body';
`
