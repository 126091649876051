
import React from 'react'

import { Pane } from 'evergreen-ui'

export type Props = {
    additionalTerms?: string | null
}


const MembershipContract = ({ additionalTerms }: Props) => {


    return (
        <Pane>
            <p>
                This Dental Services Membership Agreement (the “Agreement”) is entered into
                on the Commencement Date (as defined below) by and between Practice (as
                defined in Exhibit A), and the Patient as listed below; and with the
                Practice and the Patient also each referenced herein as a “Party” and
                collectively as the “Parties”.
            </p>
            <p>
                <strong>WHEREAS</strong>
                , the Practice is a subscriber to TruBlu Plan For Health, LLC Oral Wellness
                Membership Program software as a service platform (“TruBlu Software”)
            </p>
            <p>
                WHEREAS, the Practice utilizing the TruBlu Software has created specific
                oral wellness membership programs to offer its patients (“Programs”);
            </p>
            <p>
                <strong>WHEREAS</strong>
                , the Patient desires to participate in one of the Programs and engage the
                Practice to provide dental services for the Patient under the terms of this
                Agreement;
            </p>
            <p>
                <strong>WHEREAS</strong>
                , the Patient has selected the Program that includes the dental services
                and/or benefits which he/she will be provided by the Practice (the “Dental
                Services”) from the Programs offered in the Practice’s instance of the
                TruBlu Software Platform included in this online enrollment process;
            </p>
            <p>
                <strong>WHEREAS</strong>
                , based on the Patient’s Program selection(s) the TruBlu Software has
                calculated the Membership Fee (as defined below), and the Patient has
                completed the payment requirements as required through the secure portal on
                this website; and
            </p>
            <p>
                <strong>WHEREAS</strong>
                , the Patient desires to enter into this Agreement as the terms and
                conditions under which the Dental Services will be provided by the Practice
                through the Program;
            </p>
            <ol start={1}>
                <li>
                    <strong>Incorporation of Recitals</strong>
                    . The recitals are incorporated as substantive provisions of this
                    Agreement
                </li>
                <li>
                    <strong>Dental Services</strong>
                    . During the Membership Period, (as defined below), the Practice will
                    provide the Patient those Dental Services he/she has selected from the
                    menu of available Programs included in this online enrollment process.
                </li>
                <li>
                    Complications. Patient acknowledges his/her understanding and
                    acceptance that with any dental procedure there are “known and accepted
                    complications”; that is, a difficulty or problem with the procedure
                    which arises in a certain percentage of cases even when the procedure
                    is performed correctly. The Patient acknowledges that if such a
                    complication arises with the Practice’s providing of any of the Dental
                    Services, the Practice may charge an additional fee, in addition to the
                    Membership Fee, to treat the complication. The Practice may also refer
                    the Patient to a dental specialist who is not a participating provider
                    in the Program and who will charge the Patient a fee for this
                    specialist’s treatment of the complication.
                </li>
                <li>
                    <strong>Scheduling; Practice Rules &amp; Procedures</strong>
                    . The Patient is responsible for scheduling his/her appointments to
                    take advantage of the Dental Services provided by the Practice under
                    the Program. Please contact the front desk by phone or use the
                    Practice’s website to schedule an appointment where applicable..
                </li>
            </ol>
            <p>
                The Practice has established certain rules as its standard operating
                procedures (e.g.; cancellation and rescheduling of appointments; timing,
                and method of payment for any treatments/services not included in the
                Dental Services; emergency protocols; and COVID-19 infection control
                measures). These procedures are included on this website, and a paper copy
                of them will be provide to the Patient upon his/her first appointment. The
                Patient must comply with them as a condition of continued enrollment in the
                Program.
            </p>
            <ol start={5}>
                <li>
                    <strong>Changes in Dental Services &amp; Program Options</strong>
                    . During the Membership Period no changes will be made in the Dental
                    Services. However, at the conclusion of each Membership Period the
                    Practice reserves the right to alter, change, add- to, subtract- from,
                    or delete any of the Dental Services the Practice will provide in any
                    renewal of this Agreement
                </li>
            </ol>
            <p>
                The various treatments\services which the Practice provides through the
                Program, as listed in the menu, may be altered, changed, added - to,
                subtracted- from, or deleted at any time, including but not limited to, at
                the time of any renewal of this Agreement, without prior notice to the
                Patient.
            </p>
            <ol start={6}>
                <li>
                    <strong>Exclusions.</strong>
                    If an individual is already a patient of the Practice when they enroll
                    in the Program, then any treatments/services they received prior- to,
                    and any which they are in the mist- of receiving at the Commencement
                    Date, are excluded from the Plan. The Practice will provide, or will
                    continue to provide, as applicable, these treatments/services under its
                    usual, customary, and reasonable fee schedule then in effect.
                </li>
            </ol>
            <p>
                If during the Enrollment Period the Patient requests that the Practice
                provide any treatments/services which are not include in the Dental
                Services, and the Practice does provide them, they will be charged
                according to its usual, customary, and reasonable fee schedule then in
                effect.
            </p>
            <p>
                Dental Services which are begun but are not completed as of the Termination
                Date will be continued under the Program after the Termination Date; but
                only if the Patient renews this Agreement for another Enrollment Period. If
                the Patient does not renew, then the completion of these Dental Services
                will be according to the Practice’s usual, customary, and reasonable fee
                schedule then in effect.
            </p>
            <p>
                Dental Services which the Patient does not utilize by the Termination Date
                cannot be carried over with any renewal of this Agreement for a successor
                Membership Period. The Patient will not receive a refund of any portion of
                the Membership Fee for any Dental Services which the Patient does not
                utilize prior to the Termination Date.
            </p>
            <ol start={7}>
                <li>
                    <strong>Exclusive Use.</strong>
                    This Program is non-transferable by the Patient to any other person.
                </li>
                <li>
                    <strong>Membership Fee.</strong>
                    The Membership Fee is the amount listed for the Membership Period based
                    on the Dental Services selected by the Patient. The Membership Fee is
                    non-refundable. The Fee applies only to the Dental Services provided by
                    the Practice, and it does not apply to any other dental
                    treatments/services provided by the Practice or by any other Dental
                    provider, including any specialty dentist to whom the Practice may
                    refer the Patient for treatment/services.
                </li>
                <li>
                    <strong>Term.</strong>
                    The term of this Agreement shall begin on the date that the Patient
                    completes the enrollment process (the “Commencement Date”) and shall
                    continue for a term of 365 days thereafter (the “Membership Period”).
                    The 365<sup>th</sup> day being the “Termination Date.”
                </li>
                <li>
                    <strong>Termination &amp; Renewal.</strong>
                    This Agreement shall automatically terminate on the Termination Date.
                    At that time, the Patient may renew his/her membership in the Program
                    through this enrollment process under the terms and conditions the
                    Program is then offering through its Dental Services Membership
                    Agreement then in effect.
                </li>
                <li>
                    <strong>Termination by Practice</strong>
                    . The Practice reserves the right to terminate the Patient‘s enrollment
                    for any substantial failure to comply with the terms of the Program or
                    the Practice’s standard operating rules and procedures; or if the
                    patient is noncompliant with the Practice’s clinical instructions
                    /directives or otherwise is noncompliant; or if the patient is
                    disruptive or otherwise inappropriate in his\her conduct. The Practice
                    will refund the Patient a prorated amount of the Membership Fee based
                    on the amount of Dental Services the Practice has provided for the
                    Patient at the time of such a termination. The Practice will charge the
                    Patient for these Dental Services according to its usual, customary,
                    and reasonable fee schedule then in effect. If these charges are in
                    excess of the Membership Fee, then the Patient will be charged for any
                    amount in excess.
                </li>
                <li>
                    <strong>Questions\Concerns</strong>
                    . If the Patient has any questions or concerns about any aspect of the
                    Program, they can submit them to the Practice by contacting (via email
                    or through the practice’s website.)
                </li>
                <li>
                    <strong>Disclaimer. </strong>
                    This Program is not insurance and is not intended to replace dental
                    insurance. It is not a Qualified Health Plan under the Affordable Care
                    Act. The Program and the Practice are not licensed insurers, health
                    maintenance organizations, or other underwriters of health care
                    (dental) professional services. The Program, itself, does not make any
                    payments to the Practice, only the Patient makes such payments. There
                    is no collective risk between and among the individuals who become
                    Practice patients under the Program. Each patient has his/her own
                    separate account with the Practice and the Membership Fee of each
                    patient is strictly used to compensate the Practice for the Dental
                    Services it provides for the individual patient.
                </li>
                <li>
                    <strong>Entire Agreement &amp; Amendments</strong>
                    . This Agreement constitutes the entire understanding between the
                    Parties with regard to the Patient’s enrollment in the Program, and it
                    supersedes any prior oral or written agreements\understandings between
                    the Parties with regard to the Program This Agreement may be amended
                    only by a writing executed by both Parties.
                </li>
                <li>
                    <strong>Assignment of Agreement</strong>
                    . Because this Agreement is for the personal professional services of
                    the dentists at the Practice, it is not assignable by the Practice to
                    any person or entity. Because this Agreement is personal to the
                    Patient, it is similarly not assignable by the Patient to any other
                    person or entity.
                </li>
                <li>
                    <strong>Governing Law and Jurisdiction</strong>
                    . This Agreement shall be subject to, and governed by, the laws of the
                    State in which the Practice is located, and the Parties agree to the
                    exclusive jurisdiction of the state courts in the county in which the
                    Practice is located. Both Parties agree to the exclusive jurisdiction
                    and venue of these courts for any claims, complaints, causes of legal
                    action or other controversies which arise between them under this
                    Agreement and under any and all other professional and personal
                    dealings/relationships between them.
                </li>
                <li>
                    <strong>Severability</strong>
                    . If any provision of this Agreement is held to be unenforceable for
                    any reason, its unenforceability shall not affect the remainder of this
                    Agreement, which shall remain in full force and effect and enforceable
                    in accordance with its terms.

                </li> 
            {   additionalTerms?
                <li>
                    <strong>Additional Terms, Exclusions, and Disclosures</strong>
                    {". " + additionalTerms }            
                </li>:<></> 
            }
            </ol>
            <p>
                I have read, understand, and knowingly agree to, and will comply with, all
                the terms and conditions of this Agreement. I acknowledge that my
                participation in the Program is strictly voluntary.
            </p>

        </Pane>
    )
}

export default MembershipContract
