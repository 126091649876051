import gql from 'graphql-tag'

const fragments = {
    // also refactor planGridFields
    VideoFields: gql`
    fragment VideoFields on Video {
      id
      index
      title
      url
      status
    }
  `
}

export const GET_VIDEOS = gql`
  query GetVideos($status: VideoStatus) {
    videos(status : $status) { 
      ...VideoFields
    }
  }
  ${fragments.VideoFields}
`

export const GET_VIDEO_SEARCH = gql`
query videoSearch($search: String!, $indexOrder: Int!, $first: Int, $after: String) {
    videoSearch(search: $search, indexOrder: $indexOrder, first: $first, after: $after){
    edges {
          cursor
          node{
            id
            index
            title
            url
            status
          }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
  }
}
`
export const GET_VIDEO_BY_ID = gql`
query singleVideo($id: ID!) {
    singleVideo(id: $id){
    ...VideoFields
  }
}
${fragments.VideoFields}
`

export const ADD_VIDEO = gql`
  mutation addVideo($index: Int!, $title: String!, $url: String!, $status: VideoStatus!) {
    addVideo(index: $index, title: $title, url: $url, status: $status) {
      ...VideoFields
    }
  }
  ${fragments.VideoFields}
`

export const UPDATE_VIDEO = gql`
  mutation updateVideo($id: ID!,$index: Int!, $title: String!, $url: String!, $status: VideoStatus!) {
    updateVideo( id: $id, index: $index, title: $title, url: $url, status: $status) {
      ...VideoFields
    }
  }
  ${fragments.VideoFields}
`

export const DELETE_VIDEO = gql`
  mutation deleteVideo($id: ID!) {
    deleteVideo(id: $id) {
      id
    }
  }
`
