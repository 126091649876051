import React from 'react'
import { useField } from 'formik'

import { FilePicker as EvergreenFilePicker, Label, TextInputFieldProps } from 'evergreen-ui'

export interface Props extends Omit<TextInputFieldProps, 'label'> {
  name: string
  height?: number
  label?: string
}

const FileInputField = ({ height = 48, label = '', ...props }: Props) => {
  const [field, meta] = useField(props.name)
  return (
    <>
      <Label marginBottom={4} display="block">
        {label}
      </Label>
      <EvergreenFilePicker
        marginBottom={16}
        inputHeight={height}
        style={{ textAlign: props.textAlign }}
        label={label}
        {...field}
        {...props}
        isInvalid={meta.touched && !!meta.error}
      />
    </>
  )
}

export default FileInputField
