import React, { useEffect, useState } from 'react'

import { Label, Text, Pane, Switch, RadioGroup, Heading } from 'evergreen-ui'

import { DoubleTextInput, TextInputField, PDTerms } from '@pearly/lib'


import * as Types from 'types/graphql'
import { useGlobal } from 'components/global-provider'

export type Props = {
  TBCustomerNumber: any,
}

const PDExistUserFields = ({ TBCustomerNumber,...props }: Props) => {
  const global = useGlobal()
  const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM

  return (<>

    <Label marginBottom={4} alignSelf="flex-start">TruBlu Direct Account Number </Label>
    <TextInputField value={!isSystemAccount ? global.account?.code : TBCustomerNumber} disabled={true} name={''} />
    {!isSystemAccount &&
      <>
        <Label marginBottom={4} alignSelf="flex-start">Patterson Account Number (if available) </Label>
        <TextInputField name="PDAccountNumber" type="text" placeholder="Account Number" />
      </>
    }

    {isSystemAccount &&
      <>
        <Label marginBottom={4} alignSelf="flex-start">Patterson Account Number (Sent) </Label>
        <TextInputField name="PDAccountNumber" type="text" placeholder="Account Number" />
        <Label marginBottom={4} alignSelf="flex-start">Patterson Account Number (Received) </Label>
        <TextInputField name="PDAccountNumberReceived" type="text" placeholder="Account Number" />
      </>
    }
    <Label marginBottom={4} alignSelf="flex-start">NPI</Label>
    <TextInputField name="NPI" type="text" placeholder="NPI" />
    <Label marginBottom={4} alignSelf="flex-start">Account Name</Label>
    <TextInputField name="PDAccountName" type="text" placeholder="Account Name" />
    <Label marginBottom={4} alignSelf="flex-start">Address</Label>
    <TextInputField name="billingAddress" type="text" placeholder="Address" />
    <Label marginBottom={4} alignSelf="flex-start">City</Label>
    <TextInputField name="billingCity" type="text" placeholder="City" />
    <Label marginBottom={4} alignSelf="flex-start">State</Label>
    <TextInputField name="billingState" type="text" placeholder="State" />
    <Label marginBottom={4} alignSelf="flex-start">Zip</Label>
    <TextInputField name="billingZip" type="number" placeholder="Zip" />
    <Label marginBottom={4} alignSelf="flex-start">Primary Contact Name</Label>
    <TextInputField name="PDPrimaryContactName" type="text" placeholder="Primary Contact Name" />
    <Label marginBottom={4} alignSelf="flex-start">Primary Contact Number</Label>
    <TextInputField name="PDprimaryContactNumber" type="text" placeholder="Primary Contact Number" />

    <PDTerms />

  </>)
}

export default PDExistUserFields
