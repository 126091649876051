import gql from 'graphql-tag'

const fragments = {
  vendorFields: gql`
    fragment vendorFields on Vendor {
      id
      logo
      vendor
      contactPerson
      offer
      file
      code
      pricing
      vendorMapped
      status
      vendorContact {
        id
        phone
        mobile
        fax
        email
        address1
        address2
        city
        state
        zip
        URL
      }
    }
  `
}

export const GET_VENDORS = gql`
  query GetVendors {
    vendors {
      ...vendorFields
    }
  }
  ${fragments.vendorFields}
`

export const GET_VENDORS_SEARCH = gql`
query vendorSearch($search: String!, $orderByCol: String!, $order: String!, $first: Int, $after: String) {
  vendorSearch(search: $search, orderByCol: $orderByCol, order: $order, first: $first, after: $after){
    edges {
          cursor
          node{
            id
            logo
            vendor
            contactPerson
            offer
            file
            code
            pricing
            vendorMapped
            status
            vendorContact {
              id
              phone
              mobile
              fax
              email
              address1
              address2
              city
              state
              zip
              URL
            }
          }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
  }
}
`
export const GET_VENDORS_SEARCH_AND_FILTER = gql`
query getVendorFilterAndSearch($search: String!, $orderByCol: String!, $order: String!,$isPaid: Boolean, $first: Int, $after: String, $status: String) {
  getVendorFilterAndSearch(search: $search, orderByCol: $orderByCol, order: $order, isPaid: $isPaid, first: $first, after: $after, status: $status){
    edges {
          cursor
          node{
            id
            logo
            vendor
            contactPerson
            offer
            file
            code
            pricing
            vendorMapped
            status
            vendorContact {
              id
              phone
              mobile
              fax
              email
              address1
              address2
              city
              state
              zip
              URL
            }
          }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
  }
}
`
export const GET_VENDOR_BY_MAPPED = gql`
query vendorByMapped($vendorMapped: String!) {
  vendorByMapped(vendorMapped: $vendorMapped){
      id
      logo
      vendor
      contactPerson
      offer
      file
      code
      pricing
      status
      vendorContact {
        id
        phone
        mobile
        fax
        email
        address1
        address2
        city
        state
        zip
        URL
    }
  }
}
`
