import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_ONBOARDING_COMPLETE, SUBMIT_ONBOARDING_COMPLETE } from 'graphql/_onboarding'
import * as Types from 'types/graphql'

import { Pane, Paragraph, Heading, toaster } from 'evergreen-ui'
import { Card, CardHeader, Icon, Button, Spinner, OfficeCard, useToken } from '@pearly/lib'

export type Props = {
  prev: string
  setIsForward: React.Dispatch<React.SetStateAction<boolean>>
}

const OnboardingComplete = ({ prev, setIsForward }: Props) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const { refreshToken } = useToken()

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery<Types.OnboardingComplete>(
    GET_ONBOARDING_COMPLETE
  )

  const [submit, submitStatus] = useMutation<Types.SubmitOnboardingComplete>(SUBMIT_ONBOARDING_COMPLETE)
  useEffect(() => {
    if (submitStatus.error) {
      toaster.danger('Unable to complete onboarding')
      setLoading(false)
    }

    if (submitStatus.data) {
      const moveForward = async () => {
        // await refreshToken()
        // setIsForward(true)
        window.location.reload()
        // history.replace('/')
      }
      moveForward()
    }
  }, [submitStatus, history, setIsForward, refreshToken])

  return queryLoading || queryError || !queryData ? (
    <Spinner paddingTop={0} delay={1200} />
  ) : (
    <Card padding={0}>
      <CardHeader>
        <Heading size={500} flexGrow={1} textAlign="center">
          Set up your Practice
        </Heading>
      </CardHeader>

      <Pane padding={24} display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <Icon icon={['fad', 'check']} color="success" size="3x" marginBottom={16} />
        <Paragraph size={500} marginBottom={16}>
          Thank you for creating an account on TruBlu Direct.
        </Paragraph>
        <Paragraph size={500} marginBottom={24}>
          To complete the sign-up process and access the Vendor Services, please click ENTER PORTAL below.
        </Paragraph>
        <Paragraph size={500} marginBottom={24}>
          You will then need to add your Billing Details and view & accept the Service Agreement.
        </Paragraph>
        <Heading marginBottom={4} alignSelf= "self-start">Office</Heading>
        {/* <Heading marginBottom={4} >Office</Heading> */}
        <OfficeCard office={queryData.firstOffice} width="100%" textAlign="left" />
      </Pane>

      <Pane zIndex={1} elevation={0} padding={16} flexShrink="0" display="flex" justifyContent="space-between">
        <Button
          appearance="minimal"
          height={48}
          justifyContent="center"
          iconBefore={['far', 'chevron-left']}
          onClick={() => {
            setIsForward(false)
            history.push(prev)
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setLoading(true)
            submit()
          }}
          appearance="primary"
          height={48}
          justifyContent="center"
          iconAfter={['far', 'chevron-right']}
          isLoading={loading}
        >
          Enter Portal
        </Button>
      </Pane>
    </Card>
  )
}

export default OnboardingComplete
