import * as Types from '../types'
import gql from 'graphql-tag'

export enum PlanTerm {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL'
}

export type EnrollMember = 'String'
export type SavedTokenInput = Types.SavedTokenInput
export type PlanSubscriptionStatus = Types.PlanSubscriptionStatus

export interface EnrollMemberVariables {
  idToken: string
  secure: Types.SecureContact | string
  planId: string
  planTerm: PlanTerm
  isManual: boolean
  // coupon?: string
  creditCardToken?: SavedTokenInput
  initialStatus?: PlanSubscriptionStatus
}

export const ENROLL_MEMBER = gql`
  mutation enrollMember(
    $idToken: String!
    $secure: String!
    $planId: ID!
    $planTerm: PlanTerm!
    $isManual: Boolean!
    $creditCardToken: SavedTokenInput
    $initialStatus: PlanSubscriptionStatus
  ) {
    enrollMember(
      idToken: $idToken
      secure: $secure
      planId: $planId
      planTerm: $planTerm
      creditCardToken: $creditCardToken
      initialStatus: $initialStatus
      isManual: $isManual
    ) {
      id
      plan {
        id
        activeMemberCount
        allTimeMemberCount
      }
    }
  }
`

export type EnrollDependent = 'String'

export interface EnrollDependentVariables {
  idToken: string
  contactId: string
  secure: Types.SecureContact | String
  planId: string
  planTerm: PlanTerm
  isManual: boolean
  // coupon?: string
  creditCardToken?: SavedTokenInput
}

export const ENROLL_DEPENDENT = gql`
  mutation EnrollDependent(
    $idToken: String!
    $contactId: ID!
    $secure: String!
    $planId: ID!
    $planTerm: PlanTerm!
    $isManual: Boolean!
    $creditCardToken: SavedTokenInput
  ) {
    enrollDependent(
      idToken: $idToken
      contactId: $contactId
      secure: $secure
      planId: $planId
      planTerm: $planTerm
      creditCardToken: $creditCardToken
      isManual: $isManual
    ) {
      id
      plan {
        id
        activeMemberCount
        allTimeMemberCount
      }
    }
  }
`

export const ENROLL_DEPENDENT_PUBLIC = gql`
  mutation EnrollDependentPublic(
    $idToken: String!
    $contactId: ID!
    $secure: String!
    $planId: ID!
    $planTerm: PlanTerm!
    $isManual: Boolean!
    $creditCardToken: SavedTokenInput
  ) {
    enrollDependent(
      idToken: $idToken
      contactId: $contactId
      secure: $secure
      planId: $planId
      planTerm: $planTerm
      isManual: $isManual
      creditCardToken: $creditCardToken
    ) {
      id
    }
  }
`

export type UpdateContactProfile = {
  id: string
  secure: Types.SecureContact | string
}

export type UpdateContactProfileVariables = {
  id: string
  secure: Types.SecureContact | string
}

export const UPDATE_CONTACT_PROFILE = gql`
  mutation UpdateContactProfile($id: ID!, $secure: String!) {
    updateContactProfile(id: $id, secure: $secure) {
      id
      secure
    }
  }
`

export type UpdateContactNotes = {
  id: string
  secure: Types.SecureContactNotes
}

export type UpdateContactNotesVariables = {
  id: string
  secureNotes: Types.SecureContactNotes
}

export const UPDATE_CONTACT_NOTES = gql`
  mutation UpdateContactNotes($id: ID!, $secureNotes: String!) {
    updateContactNotes(id: $id, secureNotes: $secureNotes) {
      id
      secureNotes
    }
  }
`
