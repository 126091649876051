import React from 'react'
import styled from 'styled-components/macro'

import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading } from 'evergreen-ui'
import { Button, Card, CardHeader, TextInputField } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { useQuery } from '@apollo/react-hooks'
import { CLEARENT_TAXPAYER } from 'graphql/_clearent-onboarding'

export type Props = {
  merchantSetting: Types.clearentMerchantSetting
  createMerchant: [Types.ClearentCreateMerchantProp, Types.ClearentCreateMerchantStatusProp]
  updateMerchant: [Types.ClearentUpdateMerchantProp, Types.ClearentUpdateMerchantStatusProp]
  updateAddress: [Types.ClearentUpdateAddressProp, Types.ClearentUpdateAddressStatusProp]
  udpateTaxPayer: [Types.ClearentUpdateTaxPayerProp, Types.ClearentUpdateTaxPayerStatusProp],
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const ClearentLegal = ({ //merchantSetting,
  //createMerchant: [createMerchant, createMerchantStatus],
  //updateMerchant: [updateMerchant, updateMerchantStatus],
  //updateAddress: [updateAddress, updateAddressStatus],
  udpateTaxPayer: [updateTaxPayer],
  dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')

  //const [isContractSheetShown, setIsContractSheetShown] = useState(false)

  const { data } = useQuery<Types.clearentTaxPayer>(CLEARENT_TAXPAYER)

  const initialValues = {
    LegalName: data?.clearentTaxPayer.businessLegalName ?? '',
    OwnershipType: data?.clearentTaxPayer.tinTypeID ?? '',
    FederalTaxID: data?.clearentTaxPayer.tin ?? '',
    StateofRegistration: data?.clearentTaxPayer.stateIncorporatedCode ?? ''
  }

  return (
    <>
      {/* {loading || error || !data ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : ( */}
          <Formik
            initialValues={initialValues}
            validate={args => {
              if (!dirty && args !== initialValues) setDirty(true)
            }}
            onSubmit={args => {
              showConfirmDialog({
                body: 'Are you sure you want to save Legal Information?',
                confirm: () => {
                  updateTaxPayer({
                    variables: {
                      businessLegalName: args.LegalName,
                      stateIncorporatedCode: args.StateofRegistration,
                      tin: args.FederalTaxID
                    }
                  })
                }
              })
            }}
            validationSchema={Yup.object({

            })}
          ><Form style={{ height: '100%', width: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
              <SheetBodyLayout>
                <Pane gridArea="body" background="blueTint" overflow="scroll">
                  <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                    <CardHeader withButton>
                      <Heading size={500}>Legal Information</Heading>
                    </CardHeader>

                    <Pane display="flex" marginTop={8} marginX={12} >
                      <TextInputField name="LegalName" label="Legal Name" placeholder="" height={40} width="100%" />
                    </Pane>

                    <Pane display="flex" marginTop={0} marginX={12} >
                      <TextInputField name="OwnershipType" label="Ownership Type" placeholder="" height={40} width="100%" />
                    </Pane>
                    <Pane display="flex" marginTop={0} marginX={12} >
                      <TextInputField name="FederalTaxID" label="Federal Tax ID" placeholder="" height={40} width="100%" />
                    </Pane>
                    <Pane display="flex" marginTop={0} marginX={12} >
                      <TextInputField name="StateofRegistration" label="State of Registration" placeholder="" height={40} width="100%" />
                    </Pane>

                    <Pane display="flex" marginTop={0} marginBottom={20} marginX={12} >
                      <Button
                        type="submit"
                        justifyContent="center"
                        appearance="primary"
                        marginRight={8}
                        marginLeft={0}
                      >
                        Save Legal Information
                    </Button>
                      <Button
                        type="button"
                        justifyContent="center"
                      >
                        Cancel
                    </Button>
                    </Pane>
                  </Card>

                </Pane>


              </SheetBodyLayout>
            </Form>
          </Formik>
        {/* )} */}
    </>
  )
}

export default ClearentLegal

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
