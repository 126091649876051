import React from 'react'
import moment from 'moment'
import * as Types from '../types'

// import { useLazyQuery } from '@apollo/react-hooks'
// import { GET_COUPON } from '../graphql/_member-payment-fields'
// import { ApolloClient } from 'apollo-client'

// import { Formik, Form } from 'formik'
// import * as Yup from 'yup'

import { Pane, Heading, Elevation, Text } from 'evergreen-ui'
import Card from '../card'
import CardHeader from '../card-header'
import PayButton from '../pay-button'


// import TextInputFieldWithButton from '../text-input-field-with-button'

// import { capital } from 'case'

export type Props = {
  // accountId?: string
  elevation?: Elevation
  plan: {
    id: string
    name: string
    term: Types.PlanTerm
    price: number
  }
  // setCouponCodeState: React.Dispatch<React.SetStateAction<string | undefined>>
  setCreditCardToken: React.Dispatch<React.SetStateAction<Types.SavedTokenInput | undefined>>
  dependentName?: string
  parentId?: string
  // apolloClient?: ApolloClient<any>
  paymentKey?: string
}

const MemberPaymentFields = ({
  // accountId,
  elevation = 0,
  plan,
  // setCouponCodeState,
  setCreditCardToken,
  dependentName,
  parentId,
  // apolloClient,
  paymentKey
}: Props) => {
  // const { data: cardData } = useQuery<Types.GetCard, Types.GetCardVariables>(GET_CARD, {
  //   skip: !parentId,
  //   variables: {
  //     contactId: parentId!
  //   },
  //   client: apolloClient
  // })

  // const [getCoupon, { loading: couponLoading, error: couponError, data: couponData }] = useLazyQuery<
  //   Types.GetCoupon,
  //   Types.GetCouponVariables
  // >(GET_COUPON, {
  //   fetchPolicy: 'network-only',
  //   client: apolloClient
  // })

  // useEffect(() => {
  //   if (couponError) toaster.danger('Coupon not found')
  //   if (!couponLoading && couponData?.coupon?.valid) {
  //     setCouponCodeState(couponData.coupon.id)
  //     toaster.success(`Coupon successfully applied`)
  //   }
  // }, [couponLoading, couponError, couponData, setCouponCodeState])
  return (
    <>
      <Card elevation={elevation} padding={0} overflow="hidden" marginBottom={16}>
        <CardHeader justifyContent="space-between">
          <Heading size={500}>Setup Payment</Heading>
        </CardHeader>
        <PayButton id="member-payment" paymentKey={paymentKey} callBack={(token) => setCreditCardToken(token)} />
      </Card>
      <Card elevation={elevation} padding={0} marginBottom={16}>
        <CardHeader>
          <Heading size={500} flex={1} textAlign="center">
            {parentId && 'Dependent '}Payment Summary
          </Heading>
        </CardHeader>
        <Pane padding={24} display="flex" flexDirection="column">
          {dependentName && (
            <Text size={500} marginBottom={8}>
              <strong>Name: </strong>
              {dependentName}
            </Text>
          )}
          <Text size={500} marginBottom={8}>
            <strong>Plan: </strong>
            {plan?.name}
          </Text>
          <Text size={500} marginBottom={8}>
            <strong>Price: </strong>${plan?.price} / {plan?.term}
            {/* {getShownPrice(plan?.price, plan?.term, couponData?.coupon)} */}
          </Text>
          <Text size={500} marginBottom={8}>
            <strong>Term: </strong>
            {`${moment().format('M/D/YYYY')} - ${moment()
              .add(1, 'y').add(-1, 'days')
              .format('M/D/YYYY')}`}
          </Text>
          <Text size={500}>
            <strong>Billing Date: </strong>
            {moment().format('M/D/YYYY')}
          </Text>

          {/* {couponData?.coupon && (
            <Text size={500} marginTop={8}>
              <strong>Coupon Term: </strong>
              {couponData.coupon.duration === 'repeating'
                ? `${couponData.coupon.duration_in_months} months`
                : capital(couponData.coupon.duration)}
            </Text> 
          )} */}
        </Pane>
      </Card>

      {/* <Card elevation={elevation} padding={0} overflow="hidden" marginBottom={16}>
        <Pane padding={16} display="flex" flexDirection="column" alignItems="center">
          <Formik
            initialValues={{ coupon: '' }}
            onSubmit={({ coupon }) => getCoupon({ variables: { code: coupon, accountId } })}
            validationSchema={Yup.object({
              coupon: Yup.string().required()
            })}
          >
            <Form style={{ height: '100%', width: '100%' }}>
              <TextInputFieldWithButton
                isButtonLoading={couponLoading}
                height={40}
                label="Apply"
                name="coupon"
                placeholder="Promotion code..."
                width="100%"
                marginBottom={0}
                boxShadow="inset 0 0 0 1px rgba(67, 90, 111, 0.15), inset 0 1px 2px rgba(67, 90, 111, 0.07) !important"
              />
            </Form>
          </Formik>
        </Pane>
      </Card> */}
    </>
  )
}

export default MemberPaymentFields

// const getShownPrice = (basePrice: number = 0, term: Types.PlanTerm) => {
//   let shownPrice = basePrice
//   if (coupon?.valid) {
//     if (coupon?.amount_off) shownPrice = basePrice - coupon.amount_off / 100
//     if (coupon?.percent_off) shownPrice = basePrice * (1 - coupon.percent_off / 100)
//   }

//   return (
//     <span>
//       {shownPrice === basePrice ? (
//         `$${shownPrice.toFixed(2)}`
//       ) : (
//         <>
//           <span style={{ textDecoration: 'line-through' }}>{`$${basePrice.toFixed(2)}`}</span>
//           <span>{` $${shownPrice.toFixed(2)}`}</span>
//         </>
//       )}
//       {` / ${term === Types.PlanTerm.ANNUAL ? 'yr' : 'mo'}`}
//     </span>
//   )
// }
