import React from 'react'
import { useField } from 'formik'

import { Checkbox as EvergreenCheckbox, CheckboxProps, Pane, Label } from 'evergreen-ui'

export interface Props extends CheckboxProps {
  className?: string
  name: string
  label: JSX.Element
}

const Checkbox = ({ className, label, ...props }: Props) => {
  const { name } = props
  const [field, meta] = useField({ name, type: 'checkbox' })

  return (
    <Pane className={className} display="flex" alignItems="flex-start">
      <EvergreenCheckbox
        marginTop={6}
        {...props}
        flexShrink={0}
        marginRight={8}
        checked={field.checked}
        onChange={() => field.onChange({ target: { name, value: !field.checked } })}
        isInvalid={meta.touched && !!meta.error}
      />
      <Label marginTop={3}>{label}</Label>
    </Pane>
  )
}

export default Checkbox
