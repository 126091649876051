import React from 'react'

import { Pane, Dialog } from 'evergreen-ui'

import InviteFields from '../_fields/invite-fields'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
}

const SendInviteDialog = ({ isShown, setIsShown }: Props) => (
  <Dialog
    isShown={isShown}
    title="Send Text or Email Invite"
    hasFooter={false}
    onCloseComplete={() => {
      setIsShown(false)
    }}
  >
    <Pane padding={16} height="140px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <InviteFields successCB={() => setIsShown(false)} />
    </Pane>
  </Dialog>
)

export default SendInviteDialog
