import React from 'react'

import { Card as EvergreenCard, PaneProps } from 'evergreen-ui'

export interface Props extends PaneProps {
  className?: string
  children?: React.ReactNode
}

const Card = (props: Props) => {
  return (
    <EvergreenCard
      elevation={2}
      backgroundColor="white"
      padding={16}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      {...props}
    />
  )
}

export default Card
