import React, { useEffect } from 'react'
import * as Types from '../types'

import { useMutation } from '@apollo/react-hooks'
import {VOID_CONTACT_PAYMENT_EVENT} from '../graphql/_receipt'
import { ApolloClient } from 'apollo-client'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Paragraph, toaster } from 'evergreen-ui'
import Button from '../button'
import Textarea from '../textarea'


export type Props = {
  apolloClient?: ApolloClient<any>
  paymentEventId: string
  successCB?: () => void
}

const VoidPaymentFields = ({ apolloClient, paymentEventId, successCB }: Props) => {
  const [voidContactPaymentEvent, { loading, error, data }] = useMutation<Types.voidContactPaymentEvent, Types.voidContactPaymentEventVariables>(
    VOID_CONTACT_PAYMENT_EVENT,
    {
      client: apolloClient,
      refetchQueries: ['GetContactPaymentsEventsGroupLast', 'MembersTable', 'RenewalsTable', 'PaymentsTable', 'PaymentsAnalytics', 'memberBillingHistory']
    }
  )

  useEffect(() => {
    if (error) toaster.danger('Unable to void payment.')
    if (!loading && data) {
      toaster.success(`Payment voided.`)
      if (successCB) successCB()
    }
  }, [loading, error, data])

  return (
    <Formik
      initialValues={{ note: '' }}
      onSubmit={({ note }, { resetForm }) => {
            voidContactPaymentEvent({ variables: { id: paymentEventId, note: note } })
            resetForm()
      }}
      validationSchema={Yup.object({
        note: Yup.string().required('Note is required'),
      })}
    >
      <Form style={{ maxWidth: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
      <Textarea name="note" label="Notes" placeholder="Notes" height={80} />
        <Paragraph color="muted" size={300} textAlign="center">
          Please provide a reason of voiding this payment.
        </Paragraph>
        <Button
          isLoading={loading}
          type="submit"
          appearance="primary"
          height={48}
          width="100%"
          justifyContent="center"
        >
          Submit
      </Button>
      </Form>
    </Formik>
  )
}

export default VoidPaymentFields
