import { initializeIconLibrary } from './utility/icon-library'

import Button from './button'

import Card from './card'
import CardHeader from './card-header'
import Checkbox from './checkbox'
import DoubleTextInput from './double-text-input'
import TripleTextInput from './triple-text-input'
import FormError from './form-error'
import FullScreen from './full-screen'
import HeaderLink from './header-link'
import Icon from './icon'
import Link from './link'
import ProgressBar from './progress-bar'
import SelectMenu from './select-menu'
import SidebarLink from './sidebar-link'
import StatCard from './stat-card'
import Tab from './tab'
import TextInput from './text-input'
import TextInputField from './text-input-field'
import FileInputField from './file-input-field'
import TextInputWithButton from './text-input-with-button'
import TextInputFieldWithButton from './text-input-field-with-button'

import Switch from './switch'
import CardElement from './card-element'
import Textarea from './textarea'
import RadioGroup from './radio-group'
import IconButton from './icon-button'
import SegmentedControl from './segmented-control'
import LinkCard from './link-card'
import OfficeCard from './office-card'
import DentistCard from './dentist-card'
import TreatmentsTable from './treatments-table'
import DiscountsTable from './discounts-table'
import InfoTooltip from './info-tooltip'
import PlanCardList from './plan-card-list'
import PlanCardListNew from './plan-card-list-new'
import Spinner from './spinner'
import PoweredByStripe from './powered-by-stripe'
import DependentCardList from './dependent-card-list'

import MemberFields from './_fields/member-fields'
import InviteFields from './_fields/invite-fields'
import MemberStartFields from './_fields/member-start-fields'
import MemberDetailsFields from './_fields/member-details-fields'
import MemberPaymentFields from './_fields/member-payment-fields'

import ContractSheet from './_sheets/contract-sheet'
import MemberReceipt from './_sheets/member-receipt'
import PaymentRecipt from './_sheets/payment-recipt'
import UpdatePlanDateSheet from './_sheets/update-plan-date-sheet'
import UpdatePlanSheet from './_sheets/update-plan-sheet'

import EnrollMemberSheet from './_sheets/enroll-member-sheet'
import ViewMemberSheet from './_sheets/view-member-sheet'
import ViewPaymentsProblemsSheet from './_sheets/view-payments-problems-sheet'
import ViewPracticePaymentsProblemsSheet from './_sheets/view-practice-payments-problems-sheet'
import ViewServicesSheet from './_sheets/view-services-sheet'

import SendInviteDialog from './_dialogs/send-invite-dialog'
import ConfirmDialog from './_dialogs/confirm-dialog'

import { MediaQueryProvider, useMediaQueryContext } from './media-query-provider'
import { StripeElementsProvider, useStripe } from './stripe-elements-provider'
import { CreditCardProvider, useCreditCardEngine } from './credit-card-provider'
import { TokenProvider, useToken } from './token-provider'
import { VgsWrap } from './utility/vgs-utils'
import { SavedTokenInput } from './types'
import PayButton from './pay-button'
import ServiceSubscriptionCard from './service-subscription-card'
import ServiceContractSheet from './_sheets/service-contract-sheet'
import ServiceEnrollSheet from './_sheets/service-enroll-sheet'
import PaymentHistoryTable from './payment-history-table'
import ServicePaymentHistoryTable from './service-payment-history'
import AddPaymentsheet from './_sheets/add-paymentsheet'
import CardUpcomingRenewal from './card-upcoming-renewal'
import DSTerms from './TBDirect/ds-terms'
import PDTerms from './TBDirect/pd-terms'
import CandidTerms from './TBDirect/candid-terms';

initializeIconLibrary()
export { Props as ButtonProps } from './button'

export {
  Button,
  Card,
  FormError,
  FullScreen,
  HeaderLink,
  Icon,
  Link,
  SidebarLink,
  Tab,
  TextInputField,
  TextInputWithButton,
  TextInput,
  DoubleTextInput,
  TripleTextInput,
  StatCard,
  CardHeader,
  SelectMenu,
  ProgressBar,
  Checkbox,
  Switch,
  CardElement,
  Textarea,
  RadioGroup,
  IconButton,
  SegmentedControl,
  LinkCard,
  OfficeCard,
  DentistCard,
  TreatmentsTable,
  DiscountsTable,
  MemberFields,
  InviteFields,
  ContractSheet,
  MemberReceipt,
  PaymentRecipt,
  UpdatePlanDateSheet,
  UpdatePlanSheet,
  InfoTooltip,
  MemberStartFields,
  MemberDetailsFields,
  PlanCardList,
  PlanCardListNew,
  MemberPaymentFields,
  SendInviteDialog,
  ConfirmDialog,
  Spinner,
  PoweredByStripe,
  TextInputFieldWithButton,
  TokenProvider,
  useToken,
  EnrollMemberSheet,
  ViewMemberSheet,
  ViewPaymentsProblemsSheet,
  ViewPracticePaymentsProblemsSheet,
  ViewServicesSheet,
  DependentCardList,
  VgsWrap,
  SavedTokenInput,
  PayButton,
  PaymentHistoryTable,
  ServiceSubscriptionCard,
  ServiceContractSheet,
  ServiceEnrollSheet,
  AddPaymentsheet,
  ServicePaymentHistoryTable,
  CardUpcomingRenewal,
  FileInputField,
  DSTerms,
  PDTerms,
  CandidTerms
}

export { MediaQueryProvider, useMediaQueryContext }
export { StripeElementsProvider, useStripe }
export { CreditCardProvider, useCreditCardEngine }

export { discountData } from './utility/data'
export { toDollars, ageRangeToString, prettyPhoneNumber, effectiveDateRange, effectiveDateRangeInDateTime } from './utility/formatters'
export { objectEntriesGenericKey, objectKeys, isJson } from './utility/transforms'
export { colors, fontFamily } from './utility/theme'
export { postSupportForm } from './utility/hubspot'
export { createModalProvider } from './utility/createModalProvider'
export { clearentPayButton } from './utility/clearent-pay-button'
export { PlanSubscriptionStatus } from './types'


// export {
//   loginWithEmailAndPassword,
//   loginWithGoogle,
//   loginWithFacebook,
//   loginWithLinkedIn,
//   logout,
//   resetPassword
// } from './utility/auth'
