import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUser } from 'reactfire'
import { signupWithEmailAndPassword } from 'utility/auth'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_ONBOARDING_START, SUBMIT_ONBOARDING_START, UPDATE_ONBOARDING_START } from 'graphql/_onboarding'
import * as Types from 'types/graphql'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Text, Label, toaster } from 'evergreen-ui'
import {
  Card,
  CardHeader,
  Icon,
  DoubleTextInput,
  FormError,
  Button,
  Checkbox,
  Link,
  Spinner,
  useToken
} from '@pearly/lib'

export type Props = {
  next: string
  setIsForward: React.Dispatch<React.SetStateAction<boolean>>
}

const OnboardingStart = ({ next, setIsForward }: Props) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { tokenResult, refreshToken } = useToken()
  const user = useUser<firebase.User>()

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery<Types.OnboardingStart>(
    GET_ONBOARDING_START,
    {
      skip: !tokenResult || loading
    }
  )

  const [submit, submitStatus] = useMutation<Types.SubmitOnboardingStart, Types.SubmitOnboardingStartVariables>(
    SUBMIT_ONBOARDING_START
  )
  useEffect(() => {
    if (submitStatus.error) {
      toaster.danger('Unable to create account')
      setLoading(false)
    }

    if (submitStatus.data) {
      const moveForward = async () => {
        await refreshToken()
        setIsForward(true)
        history.push(next)
      }
      moveForward()
    }
  }, [submitStatus, next, history, setIsForward, refreshToken])

  const [update, updateStatus] = useMutation<Types.UpdateOnboardingStart, Types.UpdateOnboardingStartVariables>(
    UPDATE_ONBOARDING_START
  )
  useEffect(() => {
    if (updateStatus.error) {
      toaster.danger('Unable to update account')
      setLoading(false)
    }

    if (updateStatus.data) {
      setIsForward(true)
      history.push(next)
    }
  }, [updateStatus, next, history, setIsForward])

  // Show spinner while loading token, loading query, query error, or prior to initiating query
  return tokenResult === undefined || queryLoading || queryError || (user && !queryData && !loading) ? (
    <Spinner paddingTop={0} delay={1200} />
  ) : (
    <Card padding={0}>
      <CardHeader>
        <Heading size={500} flexGrow={1} textAlign="center">
          Set up your Practice
        </Heading>
      </CardHeader>
      <Formik
        initialValues={
          tokenResult
            ? {
                email: tokenResult.claims.email ?? '',
                password: '12345678',
                firstName: queryData?.meUser?.firstName ?? '',
                lastName: queryData?.meUser?.lastName ?? '',
                consent: true
              }
            : { email: '', password: '', firstName: '', lastName: '', consent: true }
        }
        onSubmit={({ email, password, firstName, lastName }) => {
          if (tokenResult) {
            setLoading(true)
            update({ variables: { id: queryData?.meUser?.id ?? '', firstName, lastName } })
          } else {
            signupWithEmailAndPassword(setLoading, email, password, async idToken => {
              if (idToken) {
                submit({ variables: { idToken, firstName, lastName } })
              } else {
                history.replace("/")
              }
            })
          }
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required('Email is required')
            .email('Please enter a valid email'),
          password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters'),
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          consent: Yup.boolean().oneOf([true], 'Consent is required')
        })}
      >
        <Form onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
          <Pane padding={24} paddingBottom={8} display="flex" flexDirection="column" alignItems="center">
            <Icon icon={['fad', 'id-card-alt']} color="primary" size="3x" marginBottom={16} />
            <Text size={500} marginBottom={16}>
              Let's start by creating your login
            </Text>
            <Pane paddingX={24} width="100%">
              <DoubleTextInput
                half="top"
                name="email"
                width="100%"
                type="text"
                disabled={!!tokenResult}
                placeholder="john@harris.com"
                icon={['fal', 'envelope']}
              />
              <DoubleTextInput
                half="bottom"
                name="password"
                type="password"
                disabled={!!tokenResult}
                placeholder="Password"
                width="100%"
                icon={['fal', 'lock']}
              />
              <Label marginBottom={4} alignSelf="flex-start">
                Full Name
              </Label>
              <DoubleTextInput half="top" name="firstName" width="100%" type="text" placeholder="John" />
              <DoubleTextInput half="bottom" name="lastName" type="text" placeholder="Harris" width="100%" />
            </Pane>
            <Checkbox
              name="consent"
              label={
                <>
                  I agree to{' '}
                  <Link href="/pages/terms_of_use.html" target="_blank" inline>
                    Terms of Use
                  </Link>{' '}
                  &{' '}
                  <Link href="/pages/Privacy_Policy.html" target="_blank" inline>
                    Privacy Policy
                  </Link>
                </>
              }
              marginBottom={0}
            />
            <FormError />
          </Pane>

          <Pane zIndex={1} elevation={0} padding={16} flexShrink="0" display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              appearance="primary"
              height={48}
              justifyContent="center"
              iconAfter={['far', 'chevron-right']}
              isLoading={loading}
            >
              Next
            </Button>
          </Pane>
        </Form>
      </Formik>
    </Card>
  )
}

export default OnboardingStart
