import React, { useState } from 'react'
import styled from 'styled-components/macro'

import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Text } from 'evergreen-ui'
import { Button, Card, CardHeader, Textarea, InfoTooltip, ContractSheet } from '@pearly/lib'

import { useModal } from 'components/modal-provider'

export type Props = {
  plan: Types.PlanSheet_plan
  update: [Types.UpdatePlanProp, Types.UpdatePlanStatusProp]
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const PlanTerms = ({ plan, update: [updatePlan, updatePlanStatus], dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [isContractSheetShown, setIsContractSheetShown] = useState(false)

  const initialValues = {
    additionalTerms: plan.additionalTerms ?? ''
  }

  return (
    <>
      <ContractSheet platformTarget={process.env.REACT_APP_PLATFORM_TARGET} isShown={isContractSheetShown} setIsShown={setIsContractSheetShown} planId={plan.id} />
      <Formik
        initialValues={initialValues}
        validate={args => {
          if (!dirty && args !== initialValues) setDirty(true)
        }}
        onSubmit={args => {
          showConfirmDialog({
            body: 'Are you sure you want to save this plan?',
            confirm: () => {
              updatePlan({ variables: { id: plan.id, ...args } })
            }
          })
        }}
        validationSchema={Yup.object({})}
      >
        <Form style={{ height: '100%', width: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
          <SheetBodyLayout>
            <Pane gridArea="body" background="blueTint" overflow="scroll">
              <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader>
                  <Heading size={500}>Plan Details</Heading>
                </CardHeader>
                <Pane padding={24} display="flex">
                  <Text size={500}>
                    View your full plan details, terms, and disclosures that members agree to upon purchase.
                  </Text>
                  <Button height={48} appearance="primary" onClick={() => setIsContractSheetShown(true)}>
                    View
                  </Button>
                </Pane>
              </Card>
              <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader>
                  <Heading size={500}>
                    Add Additional Terms, Exclusions, & Disclosures
                    <InfoTooltip content="Please review the terms of your plan with legal representation and add additional language in the box below." />
                  </Heading>
                </CardHeader>
                <Pane padding={16}>
                  <Textarea name="additionalTerms" placeholder="Enter details here..." rows={4} marginBottom={0} />
                </Pane>
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="flex-end">
              <Button
                isLoading={updatePlanStatus.called && updatePlanStatus.loading}
                type="submit"
                appearance="primary"
                height={48}
                justifyContent="center"
              >
                Save
              </Button>
            </Pane>
          </SheetBodyLayout>
        </Form>
      </Formik>
    </>
  )
}

export default PlanTerms

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
