import React from 'react'

import { Pane,Text } from 'evergreen-ui'

export type Props = {
    contractInfo: any
}


const ServiceExhibitA = ({ contractInfo }: Props) => {

    return (
        <Pane>
            <Text>
            <p>
                Capitalized terms used but not defined in this Exhibit A have the meaning
                given to those terms in the Agreement.
            </p>
            <ol type="1">
                <li>
                    {contractInfo.customerName}, a {contractInfo.stateOfOrganization} {contractInfo.entityType} with offices
                    located at {contractInfo.address} ("Customer")
                </li>
                <li>
                    Description of Services:
                </li>
                <ol type="a">
                    <li>
                        TruBlu Direct, LLC – TruBlu Program.
                    </li>
                </ol>
                <li>
                    Membership Dues:
                    <ol type="a">
                        <li>
                            ${contractInfo.serviceFee} per month
                        </li>
                    </ol>
                    <p>
                        Renewal: Automatic monthly renewal unless terminated prior to the first of each calendar month
                    </p>

                </li>
            </ol>
            </Text>
        </Pane>
    )
}

export default ServiceExhibitA