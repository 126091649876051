import React from 'react'

import { Pane, PaneProps } from 'evergreen-ui'

export interface Props extends PaneProps {
  className?: string
  children: React.ReactNode
  withButton?: boolean
}

const CardHeader = ({ className, withButton, ...props }: Props) => (
  <Pane
    flexShrink={0}
    display="flex"
    justifyContent={withButton ? 'space-between' : 'flex-start'}
    alignItems="center"
    zIndex={1}
    elevation={0}
    padding={withButton ? 10 : 16}
    paddingLeft={16}
    width="100%"
    {...props}
  />
)

export default CardHeader
