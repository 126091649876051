import gql from 'graphql-tag'

const fragments = {
    // also refactor planGridFields
    FlyerFields: gql`
    fragment FlyerFields on Flyer {
      id
      index
      title
      url
      status
    }
  `
}

export const GET_FLYERS = gql`
  query flyers($status: FlyerStatus) {
    flyers(status : $status) {
      ...FlyerFields
    }
  }
  ${fragments.FlyerFields}
`

export const GET_FLYER_SEARCH = gql`
query flyerSearch($search: String!, $indexOrder: Int!, $first: Int, $after: String) {
    flyerSearch(search: $search, indexOrder: $indexOrder, first: $first, after: $after){
    edges {
          cursor
          node{
            id
            index
            title
            url
            status
          }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
  }
}
`
export const GET_FLYER_BY_ID = gql`
query singleFlyer($id: ID!) {
    singleFlyer(id: $id){
    ...FlyerFields
  }
}
${fragments.FlyerFields}
`

export const ADD_FLYER = gql`
  mutation addFlyer($index: Int!, $title: String!, $url: String!, $status: FlyerStatus!) {
    addFlyer(index: $index, title: $title, url: $url, status: $status) {
      ...FlyerFields
    }
  }
  ${fragments.FlyerFields}
`

export const UPDATE_FLYER = gql`
  mutation updateFlyer($id: ID!,$index: Int!, $title: String!, $url: String!, $status: FlyerStatus!) {
    updateFlyer( id: $id, index: $index, title: $title, url: $url, status: $status) {
      ...FlyerFields
    }
  }
  ${fragments.FlyerFields}
`

export const DELETE_FLYER = gql`
  mutation deleteFlyer($id: ID!) {
    deleteFlyer(id: $id) {
      id
    }
  }
`
