import React from 'react'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Text, TextProps } from 'evergreen-ui'
import { colors } from './utility/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends Omit<TextProps, 'size'> {
  icon: IconProp
  size?: FontAwesomeIconProps['size']
}

const Icon = ({ icon, size, color, ...props }: Props) => {
  switch (color) {
    case 'primary':
      color = colors.blue.base
      break
    case 'success':
      color = colors.green.base
      break
    case 'warning':
      color = colors.orange.base
      break
    case 'danger':
      color = colors.red.base
      break
  }

  if (color === 'success') color = colors.green.base

  return (
    <Text {...props} color={color}>
      <FontAwesomeIcon icon={icon} size={size} data-undraggable />
    </Text>
  )
}

export default Icon
