import React from 'react'

import { Text, Table, TableProps } from 'evergreen-ui'
import Icon from './icon'
import { discountData } from './utility/data'
import { capital } from 'case'

import { useMediaQueryContext } from './media-query-provider'

export interface Props extends TableProps {
  plan: {
    globalDiscountActive: boolean
    globalDiscount: number | null
    discounts?: {
      category: string
      value: number
    }[]
  }
}

const DiscountsTable = ({ plan, ...props }: Props) => {
  const { isMobile } = useMediaQueryContext()

  const discounts = plan.globalDiscountActive
    ? discountData.map(discount => ({
        category: discount.name,
        value: plan.globalDiscount ?? 0
      }))
    : plan.discounts

  return (
    <Table {...props}>
      <Table.Head height={44}>
        <Table.TextHeaderCell flexBasis={isMobile ? 240 : 264} flexShrink={0} flexGrow={0}>
          Procedure Category
        </Table.TextHeaderCell>
        <Table.TextHeaderCell textAlign="center">Discount</Table.TextHeaderCell>
      </Table.Head>
      {discounts!
        .sort((a, b) => b.value - a.value || parseInt(a.category) - parseInt(b.category))
        .map((discount, i) => (
          <Table.Row key={i} height="auto" minHeight={40} paddingY={8}>
            <Table.Cell flexBasis={isMobile ? 240 : 264} flexShrink={0} flexGrow={0}>
              <Icon icon={['fad', 'check-circle']} color="primary" marginRight={8} />

              <Text size={300}>{capital(discount.category)}</Text>
            </Table.Cell>
            <Table.TextCell textAlign="center">{discount.value}%</Table.TextCell>
          </Table.Row>
        ))}
    </Table>
  )
}

export default DiscountsTable
