import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as EverGreenLink } from 'evergreen-ui'

// TSUPDATE once Link Props are available from evergreen
export type Props = (
  | { to: string; href?: never; onClick?: never }
  | { to?: never; href: string; onClick?: never }
  | { to?: string; href?: never; onClick: () => void }
) & {
  children?: React.ReactNode
  inline?: boolean
  [prop: string]: any
}

const Link = ({ to, inline, children, ...props }: Props) => {
  props.cursor = 'pointer'
  props.textDecoration = props.textDecoration ?? 'none'
  if (inline) props.display = 'inline'

  return (
    <>
      {to ? (
        <ReactRouterLink to={to}>
          <EverGreenLink is={inline ? 'span' : 'div'} {...props}>
            {children}
          </EverGreenLink>
        </ReactRouterLink>
      ) : (
        <EverGreenLink {...props}>{children}</EverGreenLink>
      )}
    </>
  )
}

export default Link
