import { toaster } from 'evergreen-ui'

const portalId = '12345678'

export const postSupportForm = async ({
  email,
  firstName,
  lastName,
  message
}: {
  email: string
  firstName: string
  lastName: string
  message: string
}) => {
  const formId = 'ABCDEFG'
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        name: 'email',
        value: email
      },
      {
        name: 'firstname',
        value: firstName
      },
      {
        name: 'lastname',
        value: lastName
      },
      { name: 'message', value: message }
    ],
    context: {
      pageUri: 'https://app.planforhealth.com/support',
      pageName: 'TruBlu » Support'
    }
  }

  try {
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    toaster.success('Your form support ticket was successfully submitted')
  } catch (err) {
    toaster.danger('Something went wrong - please reach out to support directly')
  }
}
