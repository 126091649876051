import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import * as Types from '../types'
import moment from 'moment'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_VIEW_MEMBER_SHEET_ACTIVITY,GET_VIEW_MEMBER_CONTACT_PAYMENT_AUDIT_LOG } from '../graphql/_view-member-sheet'

import { UPDATE_CONTACT_NOTES } from '../graphql/secure-operations'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Text, Table, toaster } from 'evergreen-ui'

import Button from '../button'
import Card from '../card'
import Icon from '../icon'
import Textarea from '../textarea'
import IconButton from '../icon-button'
import Spinner from '../spinner'
import { isJson } from '../utility/transforms'
import ConfirmDialog from '../_dialogs/confirm-dialog'

export type Props = {
  className?: string
  contact: Types.ViewMemberSheet_contact
  isAdmin: boolean
}

const ViewMemberActivity = ({ contact, className }: Props) => {
  const [isConfirmDialogShown, setIsConfirmDialogShown] = useState(false)
  const [deletedNote, setDeletedNote] = useState({} as { createdAt: number; body: string })

  const { loading, error, data } = useQuery<Types.ViewMemberSheetActivity, Types.ViewMemberSheetActivityVariables>(
    GET_VIEW_MEMBER_SHEET_ACTIVITY,
    {
      context: { secure: true },
      variables: { id: contact.id }
    }
  )

  const { data: paymentAuditLog } = useQuery<Types.ViewMemberContactPaymentAuditLog, Types.ViewMemberContactPaymentAuditLogVariables>(GET_VIEW_MEMBER_CONTACT_PAYMENT_AUDIT_LOG, {
    variables: {
      contactId: contact.id
    },
    fetchPolicy: 'network-only'
})


  const charges = data?.contact?.stripe.charges
  // const paymentAudit = paymentAuditLog
  
  const secureNotes =
    data?.contact?.secureNotes &&
    isJson(data.contact.secureNotes) &&
    (JSON.parse(data.contact.secureNotes) as Types.SecureContactNotes)

  const activityItems = charges && secureNotes ? [...charges, ...secureNotes] : charges ? charges : secureNotes
  const sortedActivityItems = activityItems && activityItems.sort((a, b) => b.createdAt - a.createdAt)
  
  const [addNote, addNoteStatus] = useMutation<Types.UpdateContactNotes, Types.UpdateContactNotesVariables>(
    UPDATE_CONTACT_NOTES,
    {
      context: { secure: true }
    }
  )

  useEffect(() => {
    if (addNoteStatus.error) toaster.danger('Unable to add note')
    else if (addNoteStatus.data && !addNoteStatus.loading) {
      toaster.success(`Note successfully added!`)
    }
  }, [addNoteStatus])

  const [deleteNote, deleteNoteStatus] = useMutation<Types.UpdateContactNotes, Types.UpdateContactNotesVariables>(
    UPDATE_CONTACT_NOTES,
    {
      context: { secure: true }
    }
  )

  useEffect(() => {
    if (deleteNoteStatus.error) toaster.danger('Unable to delete note')
    else if (deleteNoteStatus.data && !deleteNoteStatus.loading) {
      toaster.danger(`Note successfully deleted!`)
    }
  }, [deleteNoteStatus])

  return loading || error || !data ? (
    <Pane display="flex" alignItems="center" height="100%" background="blueTint">
      <Spinner delay={0} />
    </Pane>
  ) : (
    <>
      {/* Delete Note Confirm */}
      <ConfirmDialog
        isShown={isConfirmDialogShown}
        setIsShown={setIsConfirmDialogShown}
        confirm={() => {
          if (secureNotes) {
            deleteNote({
              variables: {
                id: contact.id,
                secureNotes: secureNotes.filter(
                  currentNote =>
                    deletedNote.createdAt !== currentNote.createdAt || deletedNote.body !== currentNote.body
                )
              }
            })
          }
        }}
        body="Are you sure you want to delete this note?"
      />
      <div className={className}>
        <Pane gridArea="body" background="blueTint" overflow="scroll">
          <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
            <Table borderRadius="5px" textAlign="left">
              <Table.Head borderTopRightRadius="5px" borderTopLeftRadius="5px" height={44} background="white">
                <Table.TextHeaderCell flexBasis={88} flexShrink={0} flexGrow={0}>
                  Date
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>Activity</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {sortedActivityItems &&
                  sortedActivityItems.map((item, i) =>
                    'body' in item ? (
                      <NoteRow key={i} height="auto" minHeight={48} paddingY={12} position="relative">
                        
                        <Table.TextCell flexBasis={88} flexShrink={0} flexGrow={0}>
                          {moment.unix(item.createdAt).format('M/D/YYYY')}
                        </Table.TextCell>
                        <Table.Cell>
                          <Icon icon={['far', 'sticky-note']} marginRight={4} fontSize={12} width={16} flexShrink={0} />
                          <Text size={300} flexGrow={1}>
                            {item.body}
                          </Text>
                          <Pane position="absolute" display="flex" alignItems="center" top="0" bottom="0" left="12px">
                            <IconButton
                              height={24}
                              type="button"
                              disabled={addNoteStatus.loading}
                              isLoading={deleteNoteStatus.loading}
                              onClick={() => {
                                setDeletedNote(item)
                                setIsConfirmDialogShown(true)
                              }}
                              icon={['fas', 'trash-alt']}
                              intent="danger"
                            />
                          </Pane>
                        </Table.Cell>
                      </NoteRow>
                    ) : (
                      <Table.Row key={i} height="auto" minHeight={48} paddingY={12}>
                        <Table.TextCell flexBasis={88} flexShrink={0} flexGrow={0}>
                          {moment.unix(item.createdAt).format('M/D/YYYY')}
                        </Table.TextCell>
                        <Table.Cell display="flex">
                          <Icon icon={['far', 'dollar-sign']} marginRight={4} fontSize={12} width={16} flexShrink={0} />
                          <Text size={300}>
                            {item.status === 'succeeded' ? 'Charged' : item.status === 'failed' ? 'Failed' : 'Pending'}{' '}
                            ${item.amount} - {item.plan} <br />
                            <i>Card ending in {item.last4}</i>
                          </Text>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                  {
                    paymentAuditLog?.PaymentAuditLogsForContact.map((item,index)=>{
                        return (
                          <Table.Row key={index} height="auto" minHeight={48} paddingY={12}>
                        <Table.TextCell flexBasis={88} flexShrink={0} flexGrow={0}>
                          {moment.utc(item.createdAt).format('M/D/YYYY')}
                        </Table.TextCell>
                        <Table.Cell display="flex">
                          <Text size={300}>{item.log}
                          </Text>
                        </Table.Cell>
                      </Table.Row>
                        )
                      })
                  }

                
              
                
                  
              </Table.Body>
            </Table>
          </Card>
        </Pane>
        <Pane gridArea="footer" backgroundColor="white" elevation={0} padding={0}>
          <Formik
            initialValues={{ note: '' }}
            validationSchema={Yup.object({
              note: Yup.string().required('Note body is required')
            })}
            onSubmit={async ({ note }, { resetForm }) => {
              const noteObject = { createdAt: moment().unix(), body: note }
              addNote({
                variables: {
                  id: contact.id,
                  // merge in current value to maintain notes
                  secureNotes: secureNotes ? [...secureNotes, noteObject] : [noteObject]
                },
                update: () => resetForm()
              })
            }}
          >
            <Form onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
              <Pane padding={16} textAlign="right">
                <Textarea name="note" marginBottom={16} placeholder="Enter note here..." rows={3} />
                <Button
                  disabled={deleteNoteStatus.loading}
                  isLoading={addNoteStatus.loading}
                  type="submit"
                  height={48}
                  appearance="primary"
                  justifyContent="center"
                  iconBefore={['fas', 'pencil-alt']}
                >
                  Add Note
                </Button>
              </Pane>
            </Form>
          </Formik>
        </Pane>
      </div>
    </>
  )
}

export default styled(ViewMemberActivity)`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`

const NoteRow = styled(Table.Row)`
  button {
    display: none;
  }
  :hover {
    > div:first-child > span:first-child {
      visibility: hidden;
    }

    button {
      display: block;
    }
  }
`
