import React from 'react'

import { Avatar, PaneProps, Heading } from 'evergreen-ui'
import Card from './card'

export interface Props extends PaneProps {
  dentist: {
    salutation: string
    name: string
    degree: string
  }
}

const DentistCard = ({ dentist: { salutation, name, degree }, ...props }: Props) => (
  <Card elevation={1} hoverElevation={2} {...props} display="flex" flexDirection="row" alignItems="center">
    <Avatar name={name} />

    <Heading marginLeft={8}>{`${salutation}. ${name}${degree !== 'NONE' ? `, ${degree}` : ''}`}</Heading>
  </Card>
)

export default DentistCard
