import React from 'react'

import { Pane, Text } from 'evergreen-ui'
import { colors } from '../../utility/theme'

export type Props = {
    contractInfo: any
}


const ServiceExhibitA = ({ contractInfo }: Props) => {

    return (
        <Pane>
            <Text>
                <p>
                    Capitalized terms used but not defined in this Exhibit A have the meaning
                    given to those terms in the Agreement.
                </p>
                <ol type="1">
                    <li>
                        {contractInfo.customerName}, a {contractInfo.stateOfOrganization} {contractInfo.entityType} with offices
                        located at {contractInfo.address} ("Customer")
                    </li>
                    <li>
                        DESCRIPTION OF SERVICES:
                    </li>
                    <ol type="a">
                        <li>
                            Platform Module –            <strong>Dental Line Insurance Verification</strong>
                        </li>
                    </ol>
                    <li>
                        <strong></strong>
                        <strong>FEES: </strong>
                        <p>
                            One-Time Setup Fee: <Text color={colors.red.base}>${contractInfo.setupFee}</Text> upon execution of Agreement.
                        </p>
                        <p>
                            Monthly Platform Fee:  <Text color={colors.red.base}>${contractInfo.serviceFee}</Text> charged on the first day of and for each calendar month.
                        </p>
                        <p>
                            Monthly Usage Fee:   <Text color={colors.red.base}>${contractInfo?.usageFee}</Text> per verification, charged on the <Text color={colors.red.base}>5</Text><sup>th</sup> of each month for the
                            proceeding months’ activity.
                        </p>
                        <p>
                            Renewal: Automatic monthly renewal unless terminated at least 10 days prior
                            to commencement of renewal period.
                        </p>

                    </li>
                </ol>
            </Text>
        </Pane>
    )
}

export default ServiceExhibitA