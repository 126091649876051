import React, { useEffect } from 'react'

import 'firebase/auth'
import 'firebase/storage'
import { FirebaseAppProvider, useAuth } from 'reactfire'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: 'active-bolt-321014.firebaseapp.com',
  storageBucket: 'active-bolt-321014.appspot.com'
}

const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <SetTenant>{children}</SetTenant>
    </FirebaseAppProvider>
  )
}

const SetTenant = ({ children }: { children: React.ReactNode }) => {
  const firebaseAuth = useAuth()

  useEffect(() => {
    firebaseAuth.tenantId = process.env.REACT_APP_IDP_TENANTID_PROVIDER ?? null
  }, [firebaseAuth])

  return <>{children}</>
}

export default FirebaseProvider
