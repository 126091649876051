import React from 'react'

import { Text, PaneProps, Heading } from 'evergreen-ui'

import Card from './card'
import { prettyPhoneNumber } from './utility/formatters'

export interface Props extends PaneProps {
  office: {
    address1: string
    address2: string | null
    city: string
    state: string
    zip: string
    phone: string
    email: string | null
  }
}

const OfficeCard = ({ office: { address1, address2, city, state, zip, phone, email }, ...props }: Props) => (
  <Card elevation={1} hoverElevation={2} {...props}>
    <Heading marginBottom={4}></Heading>
    <Text>
      {address1}
      {address2 && `, ${address2}`}
    </Text>

    <Text>{`${city}, ${state} ${zip}`}</Text>
    <Text>{prettyPhoneNumber(phone)}</Text>
    <Text>{email??''}</Text>
  </Card>
)

export default OfficeCard
