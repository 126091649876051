import React from 'react'

import Card from './card'
import Link from './link'
import Spinner from './spinner'

import { Pane, PaneProps, BadgeProps, Heading } from 'evergreen-ui'

export interface Props extends PaneProps {
  label: string
  color: BadgeProps['color']
  stat: string | number
  link?: {
    label: string
    to: string
  }
  loading?: boolean
  children?: React.ReactNode
}

const StatCard = ({ label, color = 'blue', stat, children, link, loading, ...props }: Props) => (
  <Card justifyContent="center" alignItems="center" paddingX={16} paddingTop={20} paddingBottom={16} {...props}>
    {loading ? (
      <Spinner delay={0} />
    ) : (
      <>
        <Heading marginBottom={4}>
          {label}
        </Heading>
        <Heading size={900} textTransform="uppercase" marginBottom={-4}>
          {stat}
        </Heading>
        <Pane width="100%" display="flex" flexDirection="column" alignItems="center">
          {children}
        </Pane>

        {link && (
          <Link to={link.to} size={300} textTransform="uppercase" marginTop={children ? 0 : 16}>
            {link.label}
          </Link>
        )}
      </>
    )}
  </Card>
)

export default StatCard
