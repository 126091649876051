import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { SideSheet, Pane, Text, Heading, Label, toaster } from 'evergreen-ui'
import { Card, CardHeader, Button, TextInputField, FormError } from '@pearly/lib'
import { useQuery, useMutation} from '@apollo/react-hooks'
import { GET_SERVICE_INVOICES_TABLE } from '../../graphql/_service-invoice'
import { CREATE_SERVICE_INVOICE_ITEM } from '../../graphql/_serviceInvoiceItem'
import * as Types from 'types'
import * as Yup from 'yup'
import { Formik, Form } from 'formik';

export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void
    invoice: any
}

const InvoiceItemAddSheet = ({ isShown, setIsShown, invoice }: Props) => {
    const [createInvoiceItem, createInvoiceItemStatus] = useMutation<
        Types.CreateServiceInvoiceItem,
        Types.CreateServiceInvoiceItemVariables
        >(CREATE_SERVICE_INVOICE_ITEM, {
            refetchQueries: ['ServiceInvoiceItemByServiceInvoiceId']
    })

    
    useEffect(() => {
        if (createInvoiceItemStatus.error) toaster.danger(`Unable to create service invoice item`)
        else if (createInvoiceItemStatus.data && !createInvoiceItemStatus.loading) {
            setIsShown(false)
            toaster.success(`Invoce Item successfully added`)
        }
    }, [createInvoiceItemStatus, setIsShown])

    const { loading, error, data } = useQuery(GET_SERVICE_INVOICES_TABLE, {
        fetchPolicy: 'network-only'
    })

    return (
        <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={500} shouldCloseOnOverlayClick= {false}>
            <SheetLayout>
                <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                    <Pane display="flex" alignItems="center">
                        <Pane marginLeft={16}>
                            <Heading size={600}>
                                {invoice?.account?.name}
                            </Heading>
                            <Text size={400}>Add  Invoice Item</Text>
                        </Pane>
                    </Pane>


                </Pane>

                <Pane gridArea="body" overflow="hidden" background="blueTint">
                    <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                        <CardHeader>
                            <Heading size={500}>Add Invoice Item</Heading>
                        </CardHeader>
                        <Formik
                            initialValues={{
                                quantity: '',
                                unitprice: '',
                                description: '',
                            }}
                            onSubmit={async (values) => {
                                //await new Promise((r) => setTimeout(r, 500));
                                createInvoiceItem({
                                    variables: {                                        
                                        description: String(values.description),
                                        quantity: Number(values.quantity),
                                        unitPrice: Number(values.unitprice),
                                        serviceInvoiceId: invoice.id
                                    }
                                })
                            }}

                            validationSchema={Yup.object({
                                description: Yup.string().required('Description is required'),
                                quantity: Yup.number().required('Quantity is required'),
                                unitprice: Yup.number().required('Unit Price is required'),
                            })}
                        >
                            <Form onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
                                <Pane padding={16} display="flex" flexDirection="column">
                                    <Pane marginRight={16} flex={1}>
                                        <Label marginBottom={4} alignSelf="flex-start" display="block">
                                            Quantity
                                        </Label>
                                        <TextInputField name="quantity" placeholder="Quantity" />
                                    </Pane>

                                    <Pane marginRight={16} flex={1}>
                                        <Label marginBottom={4} alignSelf="flex-start" display="block">
                                            Unit Price
                                        </Label>
                                        <TextInputField name="unitprice" placeholder="Unit Price" />
                                    </Pane>

                                    <Pane marginRight={16} flex={1}>
                                        <Label marginBottom={4} alignSelf="flex-start" display="block">
                                            Description
                                        </Label>
                                        <TextInputField name="description" placeholder="Description" />
                                    </Pane>
                                </Pane>
                                <Pane
                                    gridArea="footer"
                                    elevation={0}
                                    padding={16}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <FormError marginTop={0} max={3} />
                                    <Button
                                        type="submit"
                                        appearance="primary"
                                        justifyContent="center"
                                    >
                                        Add
                                    </Button>
                                </Pane>
                            </Form>
                        </Formik>
                    </Card>
                </Pane>
            </SheetLayout>
        </SideSheet>
    )
}

export default InvoiceItemAddSheet

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body'
    'footer';
`