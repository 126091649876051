import gql from 'graphql-tag'

const fragments = {
  // also refactor planGridFields
  vendorFields: gql`
    fragment vendorFields on Vendor {
      id
      logo
      vendor
      contactPerson
      offer
      file
      code
      pricing
      vendorMapped
      status
      vendorContact {
        id
        phone
        mobile
        fax
        email
        address1
        address2
        city
        state
        zip
        URL
      }
    }
  `
}

export const ADD_VENDOR = gql`
  mutation AddVendor(
    $logo: String, $vendor: String!, $contactPerson: String!, $offer: String!, $file: String, $code: String!,
    $phone: String, $mobile: String, $fax: String, $email: String, $address1: String!, $address2: String, $city: String, $state: State,$pricing: Float,
    $zip: String!, $URL: String,$vendorMapped: String,$status:VendorStatus!
    ) {
    addVendor(
      logo: $logo, vendor: $vendor, contactPerson: $contactPerson, offer: $offer, file: $file, code: $code,
      phone: $phone, mobile: $mobile, fax: $fax, email: $email, address1: $address1, address2: $address2, city: $city, state: $state, zip: $zip, URL: $URL,pricing: $pricing, vendorMapped:$vendorMapped,status:$status
      ) {
      ...vendorFields
    }
  }
  ${fragments.vendorFields}
`

export const UPDATE_VENDOR = gql`
  mutation UpdateVendor(
    $id: ID!, $logo: String, $vendor: String, $contactPerson: String, $offer: String, $file: String, $code: String,
    $phone: String, $mobile: String, $fax: String, $email: String, $address1: String, $address2: String, $city: String, $state: State,$pricing: Float,
    $zip: String , $URL: String, $vendorMapped: String,$status:VendorStatus!
    ) {
    updateVendor(
      id: $id, logo: $logo, vendor: $vendor, contactPerson: $contactPerson, offer: $offer, file: $file, code: $code,
      phone: $phone, mobile: $mobile, fax: $fax, email: $email, address1: $address1, address2: $address2, city: $city, state: $state, zip: $zip, URL: $URL,pricing: $pricing, vendorMapped:$vendorMapped,status:$status
      ) {
      ...vendorFields
    }
  }
  ${fragments.vendorFields}
`

export const DELETE_VENDOR = gql`
  mutation DeleteVendor($id: ID!) {
    deleteVendor(id: $id) {
      id
    }
  }
`
