import gql from 'graphql-tag'

const fragments = {
  userFields: gql`
    fragment userFields on User {
      id
      firstName
      lastName
    }
  `,
  accountFields: gql`
    fragment accountFields on Account {
      id
      name
      offices {
        id
        name
        address1
        address2
        city
        state
        zip
        phone
        email
      }
    }
  `,
  officeFields: gql`
    fragment officeFields on Office {
      id
      name
      address1
      address2
      city
      state
      zip
      phone
      email
    }
  `,
  dentistFields: gql`
    fragment dentistFields on Dentist {
      id
      salutation
      name
      degree
    }
  `
}

export const GET_ONBOARDING_START = gql`
  query OnboardingStart {
    meUser {
      ...userFields
    }
  }
  ${fragments.userFields}
`

export const SUBMIT_ONBOARDING_START = gql`
  mutation SubmitOnboardingStart($idToken: String!, $firstName: String!, $lastName: String!) {
    onboardingStart(idToken: $idToken, firstName: $firstName, lastName: $lastName) {
      ...userFields
    }
  }
  ${fragments.userFields}
`

export const UPDATE_ONBOARDING_START = gql`
  mutation UpdateOnboardingStart($id: ID!, $firstName: String!, $lastName: String!) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName) {
      ...userFields
    }
  }
  ${fragments.userFields}
`

export const GET_ONBOARDING_PRACTICE = gql`
  query OnboardingPractice {
    account {
      ...accountFields
    }
  }
  ${fragments.accountFields}
`

export const SUBMIT_ONBOARDING_PRACTICE = gql`
  mutation SubmitOnboardingPractice(
    $name: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: State!
    $zip: String!
    $phone: String!
    $email: String!
  ) {
    onboardingPractice(
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      email: $email
    ) {
      ...accountFields
    }
  }
  ${fragments.accountFields}
`

export const UPDATE_ONBOARDING_PRACTICE = gql`
  mutation UpdateOnboardingPractice(
    $name: String!
    $officeId: ID!
    $address1: String!
    $address2: String
    $city: String!
    $state: State!
    $zip: String!
    $phone: String!
    $email: String!
  ) {
    updateAccount(name: $name) {
      id
      name
    }
    updateOffice(
      id: $officeId
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      email: $email
    ) {
      ...officeFields
    }
  }
  ${fragments.officeFields}
`

export const GET_ONBOARDING_OFFICES = gql`
  query OnboardingOffices {
    offices {
      ...officeFields
    }
  }
  ${fragments.officeFields}
`

export const GET_ONBOARDING_DENTISTS = gql`
  query OnboardingDentists {
    dentists {
      ...dentistFields
    }
  }
  ${fragments.dentistFields}
`

export const SUBMIT_ONBOARDING_DENTISTS = gql`
  mutation SubmitOnboardingDentists($salutation: Salutation!, $name: String!, $degree: Degree!) {
    onboardingDentists(salutation: $salutation, name: $name, degree: $degree) {
      ...dentistFields
    }
  }
  ${fragments.dentistFields}
`

export const GET_ONBOARDING_COMPLETE = gql`
  query OnboardingComplete {
    firstOffice {
      ...officeFields
    }
    meUser {
      id
      firstName
    }
  }
  ${fragments.officeFields}
`

export const SUBMIT_ONBOARDING_COMPLETE = gql`
  mutation SubmitOnboardingComplete {
    onboardingComplete
  }
`
