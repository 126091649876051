import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import Tab from './tab'

export type Props = {
  to: string
  children: string
}

export default ({ to, children }: Props) => {
  let match = useRouteMatch({
    path: to,
    exact: true
  })

  return (
    <Link to={to}>
      <Tab size={300} textTransform="uppercase" isSelected={!!match}>
        {children}
      </Tab>
    </Link>
  )
}
