import React, { createContext, useContext, useMemo } from 'react'
import useMedia from 'use-media'

interface Props {
  children: React.ReactNode
}

export const MediaQueryContext = createContext({} as { isMobile: boolean; isTablet: boolean })

const mediaQueries = {
  mobile: '(max-width: 768px)',
  tablet: '(max-width: 992px)'
}

export const MediaQueryProvider = ({ children }: Props) => {
  const isMobile = useMedia(mediaQueries.mobile)
  const isTablet = useMedia(mediaQueries.tablet)
  const value = useMemo(() => ({ isMobile, isTablet }), [isMobile, isTablet])

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext)
}
