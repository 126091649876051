import cuid from 'cuid'

import { apps, initializeApp } from 'firebase/app'

export const signupMember = async (email: string) => {
  // try/catch block implemented in outer method, part of a broader sequence
  const memberFirebase =
    // Use Boolean OR, not Nullish Coalescing (returns undefined)
    apps.find(app => app.name === 'member') ||
    (await initializeApp(
      {
        apiKey: process.env.REACT_APP_FIREBASE_KEY ?? process.env.GATSBY_FIREBASE_KEY,
        authDomain: 'trublu-membership.firebaseapp.com'
      },
      'member'
    ))

  memberFirebase.auth().tenantId = process.env.REACT_APP_IDP_TENANTID_MEMBER ?? process.env.GATSBY_IDP_TENANTID ?? null

  const member = await memberFirebase.auth().createUserWithEmailAndPassword(email, cuid())
  await memberFirebase.auth().signOut()
  return member
}
