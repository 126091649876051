import React from 'react'

import { Spinner as EvergreenSpinner, SpinnerProps } from 'evergreen-ui'

export interface Props extends SpinnerProps {
  providerView?: boolean
}

const Spinner = ({ providerView, delay = 600, size = 36, marginX = 'auto', ...props }: Props) => {
  if (providerView) {
    props = { marginTop: 'calc(50vh - 25px - 18px)', ...props }
  }

  return <EvergreenSpinner delay={delay} size={size} marginX={marginX} {...props} />
}

export default Spinner
