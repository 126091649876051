import gql from 'graphql-tag'

export const GET_USERS_TABLE = gql`
  query UsersTable {
    users {
      id
      firstName
      lastName
      google {
        email
        verified
        role
      }
      newsletterSubscription
    }
  }
`
export const GET_INCOMPLETE_USERS_TABLE = gql`
  query getIncompleteUsersTable($search: String!) {
    getIncompleteUsers(search: $search) {
      id
      firstName
      lastName
      google {
        email
        role
        onboardingStep
        verified
      }
    }
  }
`

export const USERS_TABLE_RESEND_INVITE = gql`
  mutation UsersTableResendInvite($id: ID!) {
    resendUserInvite(id: $id) {
      id
    }
  }
`
