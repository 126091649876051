import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { SidebarTab, SidebarTabProps, Pane } from 'evergreen-ui'
import Icon from './icon'
import { colors } from './utility/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends SidebarTabProps {
  to: string
  match?: string | string[]
  icon?: IconProp
  children: string
}

export default ({ to, match, icon, children, ...props }: Props) => {
  let isMatched = useRouteMatch({
    path: match,
    exact: true
  })

  return (
    <Link to={to}>
      <SidebarTab size={500} isSelected={!!isMatched} {...props}>
        {icon && (
          <Pane display="inline-block" width="28px">
            <Icon icon={icon} color={isMatched ? colors.blue.base : 'auto'} />
          </Pane>
        )}
        {children}
      </SidebarTab>
    </Link>
  )
}
