import gql from 'graphql-tag'

export const GET_EMAIL_LOGS = gql`
query GetEmailLogs($accountId:ID, $emailId:Int, $search:String!, $startDate:String, $endDate:String, $category:EmailCategoryType!, $userType:EmailCategoryUserType!, $filterType:String!, $first: Int, $after: String) {
  getEmailLogs(accountId:$accountId, emailId:$emailId, search:$search, startDate:$startDate, endDate:$endDate, category:$category, userType:$userType, filterType:$filterType, first: $first, after: $after) {
    edges {
      cursor
      node {
        id
        templateCode
        accountId
        account{
          name
        }
        emailId
        action
        to
        cc
        bcc
        subject
        body
        category
        userType
        files
        createdAt
        sentDate
      }
    }
    pageInfo {
      endCursor 
      hasNextPage
    }
  }
}
`

export const GET_EMAIL_LOG = gql`
query GetEmailLog($id:ID!) {
  getEmailLog(id: $id) {
    id
    templateCode
    accountId
    emailId
    action
    to
    cc
    bcc
    subject
    body
    category
    userType
    files
    createdAt
    sentDate
    accountUsers
  }
}
`

export const RESEND_EMAIL = gql`
  query ResendEmail(
      $id: ID!,
      $templateCode: String!,
      $accountId: ID!,
      $action: String!,
      $emailId: Int!,
      $to: String!,
      $cc: String!,
      $bcc: String!,
      $subject: String!,
      $body: String!,
      $category:EmailCategoryType!,
      $userType:EmailCategoryUserType!,
      $files: String!
    ) {
    resendEmail(
      id:$id,
      templateCode:$templateCode,
      accountId:$accountId,
      action:$action,
      emailId:$emailId,
      to:$to,
      cc:$cc,
      bcc:$bcc,
      subject:$subject,
      body:$body,
      category:$category,
      userType:$userType,
      files:$files
    ) {
      id
      templateCode
      accountId
      emailId
      action
      to
      cc
      bcc
      subject
      body
      category
      userType
      files
      createdAt
      sentDate
  }
}
`