import React from 'react'
import * as Types from '../types'

import styled from 'styled-components/macro'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Card, Pane, Text, Heading } from 'evergreen-ui'

import Button from '../button'
import CardHeader from '../card-header'
import FormError from '../form-error'
import MemberStartFields from '../_fields/member-start-fields'

export type Props = {
  enrollmentState: Types.EnrollmentState
  updateEnrollmentState: (updatedState: Types.EnrollmentState) => void
  next: () => void
  parentId?: string
}

const EnrollMemberStart = ({ enrollmentState, updateEnrollmentState, next, parentId }: Props) => {
  const startState = enrollmentState.start

  return (
    <Formik
      initialValues={{ email: startState?.email ?? '', consent: true }}
      onSubmit={({ email }) => {
        updateEnrollmentState({ start: { email }, enforce_credit: false })
        next()
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .required('Email is required')
          .email('Please enter a valid email'),
        consent: Yup.boolean().oneOf([true], 'Consent is required')
      })}
    >
      <Form style={{ height: '100%', width: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
        <SheetBodyLayout>
          <Pane gridArea="body" background="blueTint" overflow="scroll">
            <Card backgroundColor="white" elevation={0} margin={16} marginTop={4}>
              <CardHeader>
                <Heading size={500} flex={1} textAlign="center">
                  {parentId ? 'Dependent' : 'Primary Member'} Details
                </Heading>
              </CardHeader>
              <Pane padding={24} display="flex" flexDirection="column" alignItems="center">
                <Text size={500} marginBottom={16} textAlign="center">
                  Enter the email address for the {parentId ? 'dependent' : 'new'} member – they will be sent{' '}
                  {parentId ? 'a welcome' : 'an invitation'} email once enrollment is complete.
                </Text>
                <MemberStartFields provider />
                <FormError />
              </Pane>
            </Card>
          </Pane>
          <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              appearance="primary"
              height={48}
              justifyContent="center"
              iconAfter={['far', 'chevron-right']}
            >
              Next
            </Button>
          </Pane>
        </SheetBodyLayout>
      </Form>
    </Formik>
  )
}

export default EnrollMemberStart

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'body'
    'footer';
  grid-template-rows: 1fr auto;
`
