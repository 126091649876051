import React from 'react'
import * as Types from '../../types'
import styled from 'styled-components/macro'
import moment from 'moment'

import Button from '../../button'

import { ApolloClient } from 'apollo-client'
import { useQuery } from '@apollo/react-hooks'

import { GET_SERVICE_PLAN, GET_FIRST_OFFICE_SERVICE_PLAN } from '../../graphql/_service-agreement'

import { SideSheet, Pane, Paragraph, Text, Heading } from 'evergreen-ui'
import CardHeader from '../../card-header'
import Spinner from '../../spinner'

import { effectiveDateRangeInDateTime } from '../../utility/formatters'
import { colors } from '../../utility/theme'
import { useMediaQueryContext } from '../../media-query-provider'
import ServiceContract from '../contract/service-contract'
import ServiceExhibitA from '../contract/service-exhibit-a'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  planId: string
  effectiveDate?: any
  endDate?: any
  planTerm?: Types.PlanTerm
  cancelled?: boolean
  apolloClient?: ApolloClient<any>
  dependent?: boolean
  serviceStatus?: any
  isLoading: any
  updateStatus?: any
  serviceFee?: any
  setupFee?: any
  practiceName?: any
  fullName?: any
  acceptDate?: any
  firstBilling?: any
  usageFee?: any
  isSubscription?: boolean
}

const ServiceContractSheet = ({
  isShown,
  setIsShown,
  planId,
  effectiveDate,
  endDate,
  cancelled,
  apolloClient,
  serviceStatus,
  isLoading,
  updateStatus,
  serviceFee,
  setupFee,
  practiceName,
  fullName,
  acceptDate,
  firstBilling,
  usageFee,
  isSubscription
}: Props) => {
  const { isMobile } = useMediaQueryContext()
  const { loading, error, data } = useQuery<Types.ServicePlan, Types.ServicePlanVariables>(GET_SERVICE_PLAN, {
    variables: { id: planId! },
    skip: !isShown || !planId,
    client: apolloClient
  })


  const plan = data?.plan

  const { data: dataOffice } = useQuery<Types.FirstOfficeServicePlan>(GET_FIRST_OFFICE_SERVICE_PLAN)

  // NEED A QUERY TO GET THIS INFO FROM DB

  const currentSub = (dataOffice !== null) ? dataOffice?.firstOffice : null

  var contract = {
    customerName: practiceName,
    stateOfOrganization: currentSub?.state,
    entityType: 'Practice',
    address: `${currentSub?.address1} ${(currentSub?.address2)}, ${currentSub?.zip}`,
    setupFee: isSubscription ? setupFee ?? 0 : plan?.setupFee ?? 0,
    serviceFee: isSubscription ? serviceFee ?? 0 : plan?.monthlyPrice ?? 0,
    usageFee: isSubscription ? usageFee ?? 0 : plan?.additionalServiceFeeValue ?? 0
  }

  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1)
  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={600}
      shouldCloseOnOverlayClick= {false}
      preventBodyScrolling={true}
      //@ts-ignore
      position={isMobile ? 'bottom' : 'right'}
    >
      {loading || error || !plan ? (
        <Pane
          display="flex"
          alignItems="center"
          background="blueTint"
          height={isMobile ? 'calc(100vh - 56px)' : '100%'}
        >
          <Spinner delay={0} />
        </Pane>
      ) : (
        <SheetLayout>
          <CardHeader gridArea="header">
            <Heading size={600}>{plan.name}</Heading>
            {/* <Button iconBefore={['fas', 'print']} onClick={() => window.print()}>
               Print
             </Button> */}
          </CardHeader>

          <Pane gridArea="body" overflow="scroll" height={isMobile ? 'calc(100vh - 112px)' : '100%'}>
            <Pane margin={24}>
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                Overview
              </Heading>
              <Paragraph marginBottom={24}>
                TruBlu Dental Line, LLC provides automated real-time dental insurance verification software
                (cloud-based along with certain practice management software integrations).
              </Paragraph>
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                Plan Details
              </Heading>
              <Pane display="flex" flexDirection="column" marginBottom={24}>
                <Text overflowWorp="break-word" wordWrap="break-word" marginBottom={4}>
                  <strong>Plan: </strong>
                  {plan.name}
                </Text>
                <Text marginBottom={4}>
                  <strong>Effective Dates: </strong>
                  {effectiveDateRangeInDateTime(effectiveDate, endDate)}
                </Text>

                <Text marginBottom={4}>
                  <strong>{cancelled ? 'Termination' : 'Renewal'} Date: </strong>
                  {`${(effectiveDate ? moment.utc(endDate).add(1, "days").format('M/D/YYYY') : moment().add(1, 'month').format('M/D/YYYY'))}`}
                </Text>

                <Text marginBottom={4}>
                  <strong>One-Time Setup Fee: </strong> ${isSubscription ? setupFee ?? 0 : plan?.setupFee ?? 0} upon execution of Agreement.
                </Text>

                <Text marginBottom={4}>
                  <strong>Monthly Platform Fee: </strong> ${isSubscription ? serviceFee ?? 0 : plan?.monthlyPrice ?? 0} charged on the first day of and for each calendar month.
                </Text>

                <Text marginBottom={4}>
                  <strong>Monthly Platform Fee Start Date:  </strong> {firstBilling ? moment(firstBilling).format('M/D/YYYY') : `${nextMonth.getDate()}/${nextMonth.getMonth() + 1}/${nextMonth.getFullYear()}`}
                </Text>

                <Text marginBottom={4}>
                  <strong>Monthly Usage Fee:  </strong> ${isSubscription ? usageFee ?? 0 : plan?.additionalServiceFeeValue ?? 0} per verification, charged on the <Text color={colors.red.base}>5</Text><sup>th</sup> of each month for the proceeding months’ activity.
                </Text>

                <Text marginBottom={4}>
                  <strong>Renewal: </strong>
                  Automatic monthly renewal unless terminated prior to the first of each calendar month.
                </Text>


                {/* <Text marginBottom={4}>
                  <strong>Service Fee: </strong> ${serviceFee}
                </Text> */}
              </Pane>
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                TRUBLU DENTAL LINE, LLC SERVICE AGREEMENT
              </Heading>
              <ServiceContract />
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8} id="ExhibitA">
                Service Exhibit A
              </Heading>
              <ServiceExhibitA contractInfo={contract ?? ''} />
              {(serviceStatus === 'PENDING') ?
                <Button marginRight={8} marginTop={10} intent="success" onClick={() => updateStatus()} isLoading={isLoading}>
                  Accept the contract
                </Button> : null}
              {(serviceStatus !== 'PENDING') ?
                <Text> Signed by: {fullName}   | Date: {moment.utc(acceptDate).format('M/D/YYYY')} </Text>
                : null}
            </Pane>
          </Pane>
        </SheetLayout>
      )}
    </SideSheet>
  )
}

export default ServiceContractSheet

const SheetLayout = styled.div`
   height: 100%;
   display: grid;
   grid-template-areas:
     'header'
     'body';
   grid-template-rows: auto 1fr;
 `