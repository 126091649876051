import gql from 'graphql-tag';

const fragments = {
  psUserFields: gql`
      fragment psUserFields on PDUser {
        id
        account {
          id
          code
          name
        }
        accountId
        isNew
        PDAccountNumber
        PDAccountNumberReceived
        NPI
        PDAccountName
        billingAddress
        billingCity
        billingState
        billingZip
        PDPrimaryContactName
        PDprimaryContactNumber
        createdAt
        activatedAt
        cancelAt
        lastUpdated
        status
        sentToPD
        agreementURL
        vendorSubscriptions {
          status
          createdAt
          startDate
          endDate
          fee
          nextPaymentDate
          firstBilling
          acceptDate
        }
      }
    `
}


export const Existing_PDAccount = gql`
  mutation existingPDAccount(
    $PDAccountNumber: String
    $NPI: String
    $PDAccountName: String
    $billingAddress: String
    $billingCity: String
    $billingState: State
    $billingZip: Int
    $PDPrimaryContactName: String 
    $PDprimaryContactNumber: String
    $vendorId: ID!,
    $fee: Float!
    ) {
      existingPDAccount(
        PDAccountNumber: $PDAccountNumber,
        NPI: $NPI,
        PDAccountName: $PDAccountName,
        billingAddress: $billingAddress,
        billingCity: $billingCity,
        billingState: $billingState,
        billingZip: $billingZip,
        PDPrimaryContactName: $PDPrimaryContactName,
        PDprimaryContactNumber: $PDprimaryContactNumber,
        vendorId: $vendorId,
        fee: $fee
      ) {
      ...psUserFields
    }
  }
  ${fragments.psUserFields}
`

export const UPDATE_PDACCOUNT = gql`
mutation updatePDAccount(
  $id: ID!,
  $PDAccountNumber: String
  $PDAccountNumberReceived: String
  $NPI: String
  $PDAccountName: String
  $billingAddress: String
  $billingCity: String
  $billingState: State
  $billingZip: Int
  $PDPrimaryContactName: String 
  $PDprimaryContactNumber: String
  $vendorId: ID!,
  $status: PDUserStatus,
  ) {
    updatePDAccount(
      id: $id,
      PDAccountNumber: $PDAccountNumber
      PDAccountNumberReceived: $PDAccountNumberReceived
      NPI: $NPI
      PDAccountName: $PDAccountName
      billingAddress: $billingAddress
      billingCity: $billingCity
      billingState: $billingState
      billingZip: $billingZip
      PDPrimaryContactName: $PDPrimaryContactName
      PDprimaryContactNumber: $PDprimaryContactNumber
      vendorId: $vendorId,
      status: $status,
    ) {
    ...psUserFields
  }
}
${fragments.psUserFields}
`