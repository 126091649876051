import ClearentAddress from './clearent-address'
import ClearentBank from './clearent-bank'
import ClearentMerchant from './clearent-merchant'
import ClearentMailingAddress from './clearent-mailing-address'
import ClearentLegal from './clearent-legal'
import ClearentOwner from './clearent-owner'

export default {
    Address: ClearentAddress,
    MailingAddress: ClearentMailingAddress,
    Bank: ClearentBank,
    Merchant: ClearentMerchant,
    Legal: ClearentLegal,
    Owner: ClearentOwner
}
