import React from 'react'

import { Pane } from 'evergreen-ui'
import DoubleTextInput from '../double-text-input'
import Checkbox from '../checkbox'
import Link from '../link'

export type Props = {
  provider?: boolean
}

const urlBase = process.env.GATSBY_PROVIDER_URL??''

const MemberStartFields = ({ provider = false }: Props) => (
  <>
    <Pane paddingX={24} width="100%">
      <DoubleTextInput
        half={provider ? undefined : 'top'}
        name="email"
        width="100%"
        type="text"
        placeholder="john@harris.com"
        icon={['fal', 'envelope']}
      />
      {!provider && (
        <DoubleTextInput
          half="bottom"
          name="password"
          type="password"
          placeholder="Password"
          width="100%"
          icon={['fal', 'lock']}
        />
      )}
    </Pane>
    <Checkbox
      name="consent"
      label={
        <>
          {provider ? 'Member agrees' : 'I agree'} to the{' '}
          <Link href={`${urlBase}/pages/terms_of_use.html`} target="_blank" inline>
            Terms of Use
          </Link>
          ,{' '}
          <Link href={`${urlBase}/pages/Privacy_Policy.html`} target="_blank" inline>
            Privacy Policy
          </Link>
          , and {provider ? 'consents' : `consent`} to email, phone, and text communications
        </>
      }
      marginBottom={0}
    />
  </>
)

export default MemberStartFields
