import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import { DELETE_OFFICE, GET_OFFICES } from 'graphql/_office-sheet'
import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Heading, toaster, TextInputField } from 'evergreen-ui'
import { Card, CardHeader, Button, FormError } from '@pearly/lib'

//import OfficeFields from 'components/_fields/office-fields'
import { useModal } from 'components/modal-provider'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  bank?: {
    BankName: string
    BankAccountType: string
    RoutingNumber: number
    AccountNumber: number
    NameOnAccount: string
  }
  
}

const BankSheet = ({ isShown, setIsShown, bank }: Props) => {
  const showConfirmDialog = useModal('confirm')

  /*
  const [upsertOffice,  upsertStatus] = useMutation<Types.UpsertOffice, Types.UpsertOfficeVariables>(UPSERT_OFFICE, {
    update: (cache, { data }) => {
      // return if performing single office update (Apollo automatically updates cache)
      if (bank || !data) return

      const cachedData = cache.readQuery<Types.GetOffices>({ query: GET_OFFICES })
      if (cachedData) {
        cache.writeQuery({
          query: GET_OFFICES,
          data: { offices: cachedData.offices.concat([data.upsertOffice]) }
        })
      }
    }
  })
  */
/*
  useEffect(() => {
    if (upsertStatus.error) toaster.danger(`Unable to ${bank ? 'update' : 'create'} bank`)
    else if (upsertStatus.data && !upsertStatus.loading) {
      setIsShown(false)
      toaster.success(`Office successfully ${bank ? 'updated' : 'created'}!`)
    }
  }, [upsertStatus, bank, setIsShown])
  */

  const [deleteOffice, deleteStatus] = useMutation<Types.DeleteOffice, Types.DeleteOfficeVariables>(DELETE_OFFICE, {
    variables: { id: bank?.BankName ?? '' },
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetOffices>({ query: GET_OFFICES })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_OFFICES,
          data: { offices: cachedData.offices.filter(office => office.id !== data.deleteOffice.id) }
        })
      }
    }
  })

  useEffect(() => {
    if (deleteStatus.error) toaster.danger('Unable to delete office')
    else if (deleteStatus.data && !deleteStatus.loading) {
      setIsShown(false)
      toaster.danger(`Office successfully deleted!`)
    }
  }, [deleteStatus, setIsShown])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick= {false}>
      <Formik
        initialValues={
          bank
            ? { ...bank }
            : { BankName: '', BankAccountType: '', RoutingNumber: '', AccountNumber: '', NameOnAccount: '' }
        }
        onSubmit={({ BankName }) => {
          showConfirmDialog({
            body: `Are you sure you want to ${bank ? 'update' : 'create'} this bank?`,
            confirm: () => {
             
            }
          })
        }}
        validationSchema={Yup.object({
          BankName: Yup.string().required('Bank Name is required'),
          BankAccountType: Yup.string().required('Bank Account Type is required'),
          RoutingNumber: Yup.string().required('Routing Number is required'),
          AccountNumber: Yup.string().required('Account Number is required'),
          NameOnAccount: Yup.string().required('Name on Account is required')
        })}
      >
        <Form style={{ height: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
          <SheetLayout>
            {bank ? (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>{bank.BankName}</Heading>
                <Paragraph size={400}>Bank Details</Paragraph>
              </CardHeader>
            ) : (
                <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                  <Heading size={600}>Add Bank</Heading>
                  <Paragraph size={400}>This bank will be added to your list</Paragraph>
                </CardHeader>
              )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={1} >
                <Pane display="flex" marginTop={0} marginX={12} >

                  <TextInputField name="BankName" label="Bank Name" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="BankAccountType" label="Bank Account Type" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="RoutingNumber" label="ABA (Routing Number)" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="AccountNumber" label="Account Number" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="NameOnAccount" label="Name on Account" placeholder="" height={40} width={500} paddingLeft={20} />
                </Pane>
                <FormError marginLeft={20} marginTop={0} max={2} />
                
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {bank ? (
                <Pane display="flex" justifyContent="space-between">
                  <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    //visibility={upsertStatus.loading || upsertStatus.data ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this office?',
                        confirm: () => {
                          deleteOffice()
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>
                  <Button
                    autoFocus
                    //isLoading={upsertStatus.loading || !!upsertStatus.data}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                  <Button
                    //isLoading={upsertStatus.loading || !!upsertStatus.data}
                    type="submit"
                    appearance="primary"
                    height={48}
                    width="100%"
                    justifyContent="center"
                    iconBefore={['fas', 'plus']}
                  >
                    Add Bank
                </Button>
                )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default BankSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
