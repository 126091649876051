import React from 'react'
import styled from 'styled-components/macro'

import { Pane, PaneProps } from 'evergreen-ui'

export interface Props extends PaneProps {
  className?: string
  children?: React.ReactNode
}

const FullScreen = ({ className, children, ...props }: Props) => {
  return (
    <Pane {...props} className={className} background="blueTint">
      {children}
    </Pane>
  )
}

export default styled(FullScreen)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
