import React from 'react'
import { Label } from 'evergreen-ui'
import { TextInputField } from '@pearly/lib'

const CandidUserActivateFields = () => {
    return (
        <>
            <Label alignSelf="flex-start">CandidPro Customer Number</Label>
            <TextInputField name="candidProCustomerNumber" type="text" placeholder="CandidPro Customer Number" />
        </>
    )
}

export default CandidUserActivateFields
