import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { SideSheet, Pane, Avatar, Text, Heading, Button, Card, toaster } from 'evergreen-ui'
import CardHeader from '../card-header'
import { GET_CONTACT_PLAN_SUBSCRIPTION } from '../graphql/_member-payment-fields'
import { GET_RECIPT_BY_ID, TRY_NEW_MEMBER_PAYMENT, REVERT_CONTACT_PAYMENT_EVENT } from './../graphql/_receipt'
import ConfirmDialogPayment from '../_dialogs/confirm-dialog'
import * as Types from './../types'
export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void,
    charge?: any,
    hostUrl: string,
    isAdmin?: boolean
}

const FailedContactPayment = ({
    isShown,
    setIsShown,
    charge,
    isAdmin = false
}: Props) => {

    let receiptData: any = {};
    const [isConfirmDialogPaymentShown, setIsConfirmDialogPaymentShown] = useState(false)
    const [isRevertPaymentDialogShown, setRevertPaymentDialogShown] = useState(false)

    const { data: memberReciptData } = useQuery<Types.MemberReceiptByIdQuery, Types.MemberReceiptByIdQueryVariables>(GET_RECIPT_BY_ID, {
        context: { secure: true },
        variables: {
            paymentEventId: charge ? charge.id : ''
        },
        skip: !charge.id,
        fetchPolicy: 'network-only'
    });

    receiptData = memberReciptData?.memberReceipt;

    const secure = receiptData?.secure

    const paidByCard = receiptData?.collectingMethod == 'AUTO'
    const canRetry = receiptData?.paymentStatus === 'FAILED' && paidByCard
    const canRevert = isAdmin && receiptData?.paymentStatus === 'VOIDED'

    // console.log(receiptData);

    // TRY_NEW_SERVICE_PAYMENT
    const [tryNewPaymentEvent, tryNewPaymentEventUpdate] = useMutation<Types.TryNewMemberPayment, Types.TryNewMemberPaymentVariables>(TRY_NEW_MEMBER_PAYMENT, {
        refetchQueries: ['GetContactPaymentsEventsGroupLast', 'MembersTable', 'RenewalsTable', 'PaymentsTable', 'PaymentsAnalytics']
      })
    
      useEffect(() => {
        if (tryNewPaymentEventUpdate?.data?.tryNewMemberPayment?.paymentStatus === 'FAILED'){
           toaster.danger('Unable to proccess the payment.')
        }else if (tryNewPaymentEventUpdate?.data?.tryNewMemberPayment?.paymentStatus === 'COMPLETE' && !tryNewPaymentEventUpdate.loading) {
          toaster.success(`Payment successfully complete!`)
        }
      }, [tryNewPaymentEventUpdate])
    
    const tryNewPaymentFunction = (errorInfo: any) => {
        tryNewPaymentEvent({variables: { id: errorInfo.subscriptionId, paymentId: errorInfo.id}})
        //console.log(idConPlanSub)
    }
    // # TRY_NEW_SERVICE_PAYMENT

    // Support revert payment from voided
    const [revertPaymentEvent, revertPaymentEventUpdate] = useMutation<Types.revertContactPaymentEvent, Types.revertContactPaymentEventVariables>(REVERT_CONTACT_PAYMENT_EVENT, {
        refetchQueries: ['GetContactPaymentsEventsGroupLast', 'MembersTable', 'RenewalsTable', 'PaymentsTable', 'PaymentsAnalytics', 'memberBillingHistory']
      })
    
      useEffect(() => {
        if (revertPaymentEventUpdate?.data?.revertContactPaymentEvent?.paymentStatus === 'VOIDED'){
           toaster.danger('Unable to revert the voided payment.')
        }else if (revertPaymentEventUpdate?.data?.revertContactPaymentEvent?.paymentStatus === 'COMPLETE' && !revertPaymentEventUpdate.loading) {
          toaster.success(`Payment successfully reverted!`)
          setIsShown(false)
        }
      }, [revertPaymentEventUpdate])


    const revertPaymentFunction = (charge: any) => {
        revertPaymentEvent({variables: {id: charge.id}})
    }

    // End revert payment from voided

    const { data } = useQuery<Types.GetContactPlanSubscriptions, Types.GetContactPlanSubscriptionsVariables>(GET_CONTACT_PLAN_SUBSCRIPTION, {
        variables: {
          id: charge.subscriptionId
        },
        skip: !charge.subscriptionId
    })
    
    
    if (charge.creditCard_lastfour === data?.getContactPlanSubscriptions?.creditCard_lastfour) {
        var paymentMsg = "Are you sure you want to try a new payment using the same credit card?"
    } else {
        paymentMsg = "Are you sure you want to try a new payment using the new credit card?"
    }

    var revertMessage = "Are you sure you want to revert voided payment to complete?"
    return (
        <>
            <ConfirmDialogPayment
                isShown={isConfirmDialogPaymentShown}
                setIsShown={setIsConfirmDialogPaymentShown}
                confirm={() => tryNewPaymentFunction(charge)}
                body={paymentMsg}
                intent="danger"
            />
            <ConfirmDialogPayment
                isShown={isRevertPaymentDialogShown}
                setIsShown={setRevertPaymentDialogShown}
                confirm={() => revertPaymentFunction(charge)}
                body={revertMessage}
                intent="danger"
            />
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
                width={500}
                shouldCloseOnOverlayClick= {false}
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column'
                }}
            >
                {/* {console.log(1, charge)} */}
                <SheetLayout>
                    <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
                        <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                            <Pane display="flex" alignItems="center">
                                <Avatar name={`${secure?.firstName}`} size={40} />
                                <Pane marginLeft={16}>
                                    <Heading size={600}>
                                        {secure?.firstName}
                                    </Heading>
                                    <Text size={400}>Payment Problems Overview</Text>
                                </Pane>
                            </Pane>
                        </Pane>
                    </CardHeader>
                    <Pane gridArea="body" overflow="hidden">
                        <Pane gridArea="body" background="blueTint" overflow="scroll">
                            <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                                <CardHeader>
                                    <Heading size={500}>Payment Issues</Heading>
                                </CardHeader>
                                <Pane padding={16}>
                                    <Pane padding={8} display="flex" flexDirection="column">
                                        <Text size={500} marginBottom={8}>
                                            <strong>Status: </strong>{receiptData?.paymentStatus??''}
                                        </Text>
                                        <Text size={500} marginBottom={8}>
                                            <strong>Date: </strong>{charge.paymentDate??''}
                                        </Text>
                                        {paidByCard?
                                            <>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Last Four Digits: </strong>
                                                    {charge.last4}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Exp. Date: </strong>
                                                    {charge.cardExp??''}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Type: </strong>
                                                    {receiptData?.type}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Amout: </strong>
                                                    ${charge.amount}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Error: </strong>
                                                    {charge.paymentErrorMessage??'Unknown'}
                                                </Text>
                                            </>:<>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Collected offline - </strong> {receiptData?.paymentNote??''}
                                                </Text>
                                            </>
                                        }
                                    </Pane>
                                    <Pane display="flex" justifyContent="flex-start">
                                        { canRetry?
                                            <Button marginRight={8}
                                                intent="danger"
                                                onClick={() => {
                                                    setIsConfirmDialogPaymentShown(true)
                                                }
                                                }
                                            >
                                                Try new payment
                                            </Button>:<></>
                                        }
                                        { canRevert?
                                            <Button marginRight={8}
                                                intent="danger"
                                                onClick={() => {
                                                    setRevertPaymentDialogShown(true)
                                                }
                                                }
                                            >
                                                Undo Void
                                            </Button>:<></>
                                        }
                                    </Pane>
                                </Pane>
                            </Card>
                        </Pane>
                    </Pane>
                </SheetLayout>
            </SideSheet>
        </>
    )
}


export default FailedContactPayment

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
  
`

