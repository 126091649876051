import React from 'react'

import { Pane, Label, Text } from 'evergreen-ui'
import TextInputField from '../text-input-field'
import Switch from '../switch'
import { colors } from '../utility/theme'

const MemberFields = () => (
  <>
    <Pane display="flex">
      <TextInputField
        flex={1}
        name="firstName"
        label="First Name"
        height={40}
        marginRight={16}
        placeholder="First Name"
      />
      <TextInputField flex={1} name="lastName" label="Last Name" height={40} placeholder="Last Name" />
    </Pane>

    <TextInputField name="email" label="Email" type="email" placeholder="Email" height={40} />

    <Label alignSelf="flex-start">Date of Birth</Label>
    <Pane display="flex">
      <TextInputField
        name="dobMonth"
        flex={1}
        placeholder="MM"
        height={40}
        marginRight={16}
        textAlign="center"
        hint="Month"
      />
      <TextInputField
        name="dobDay"
        flex={1}
        placeholder="DD"
        height={40}
        marginRight={16}
        textAlign="center"
        hint="Day"
      />
      <TextInputField name="dobYear" flex={2} placeholder="YYYY" textAlign="center" height={40} hint="Year" />
    </Pane>
    <Pane display="flex" alignItems="center">
      <Label alignSelf="flex-start" marginRight={16}>
        Periodontal Disease
      </Label>
      <Switch name="perio" height={24} />
    </Pane>
    <TextInputField name="phone" label="Phone" placeholder="Phone" height={40} />

    <Pane marginY={16} display="flex" justifyContent="center" alignItems="center">
      <Pane height={1} flexGrow={1} backgroundColor={colors.border.default}></Pane>
      <Text size={300} marginX={16}>
        OPTIONAL
      </Text>
      <Pane height={1} flexGrow={1} backgroundColor={colors.border.default}></Pane>
    </Pane>

    <TextInputField name="address1" label="Address 1" placeholder="Address" height={40} />
    <TextInputField name="address2" label="Address 2" placeholder="Unit/Suite" height={40} />
    <TextInputField name="city" label="City" placeholder="City" height={40} />
    <Pane display="flex">
      <TextInputField
        flex={1}
        name="state"
        label="State"
        placeholder="State"
        height={40}
        marginRight={16}
        marginBottom={0}
      />
      <TextInputField flex={1} name="zip" label="Zip Code" placeholder="Zip Code" height={40} marginBottom={0} />
    </Pane>
  </>
)

export default MemberFields
