import OnboardingStart from './onboarding-start'
import OnboardingPractice from './onboarding-practice'
import OnboardingOffices from './onboarding-offices'
import OnboardingDentists from './onboarding-dentists'
//import OnboardingBank from './onboarding-bank'
import OnboardingComplete from './onboarding-complete'

export default {
  Start: OnboardingStart,
  Practice: OnboardingPractice,
  Offices: OnboardingOffices,
  Dentists: OnboardingDentists,
  //Bank: OnboardingBank, // Leave this step to later since we have moved to clearent
  Complete: OnboardingComplete
}
