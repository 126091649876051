import React from 'react'
import styled from 'styled-components/macro'

import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading } from 'evergreen-ui'
import { Button, Card, CardHeader, Spinner } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { useQuery } from '@apollo/react-hooks'
import { /*CLEARENT_ADDRESS, CLEARENT_UPDATE_ADDRESS, CLEARENT_BANKS, */ CLEARENT_BUSINESS_CONTACTS } from 'graphql/_clearent-onboarding'

export type Props = {
  merchantSetting: Types.clearentMerchantSetting
  createMerchant: [Types.ClearentCreateMerchantProp, Types.ClearentCreateMerchantStatusProp]
  updateMerchant: [Types.ClearentUpdateMerchantProp, Types.ClearentUpdateMerchantStatusProp]
  updateAddress: [Types.ClearentUpdateAddressProp, Types.ClearentUpdateAddressStatusProp]
  udpateTaxPayer: [Types.ClearentUpdateTaxPayerProp, Types.ClearentUpdateTaxPayerStatusProp],
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const ClearentOwner = ({ //merchantSetting,
  //createMerchant: [createMerchant, createMerchantStatus],
  //updateMerchant: [updateMerchant, updateMerchantStatus],
  //updateAddress: [updateAddress, updateAddressStatus],
  //udpateTaxPayer: [updateTaxPayer, updateTaxPayerStatus],
  dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')
  const showOwnerSheet = useModal('owner')

  //const [isContractSheetShown, setIsContractSheetShown] = useState(false)

  const { loading, error, data } = useQuery<Types.clearentBusinessContacts>(CLEARENT_BUSINESS_CONTACTS)

  //const businessContacts = data?.clearentBusinessContacts ?? [];

  const initialValues = {

  }

  return (
    <>
      {loading || error || !data ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : (
          <Formik
            initialValues={initialValues}
            validate={args => {
              if (!dirty && args !== initialValues) setDirty(true)
            }}
            onSubmit={args => {
              showConfirmDialog({
                body: 'Are you sure you want to save Address?',
                confirm: () => {

                }
              })
            }}
            validationSchema={Yup.object({

            })}
          ><Form style={{ height: '100%', width: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
              <SheetBodyLayout>
                <Pane gridArea="body" background="blueTint" overflow="scroll">
                  <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                    <CardHeader withButton>
                      <Heading size={500}>
                        Owner/Signer
                      </Heading>
                      <Button iconBefore={['fas', 'plus']} onClick={() => showOwnerSheet()}>
                        Add Owner
                       </Button>
                    </CardHeader>
                    <Pane display="flex" marginTop={10} marginX={12} >
                      {/* {
                        businessContacts.map((ctc, i) => {
                          <Card elevation={1} width="100%" >
                            <Heading marginBottom={4}>{ctc.title}</Heading>
                            <Text>
                              Email: {ctc.emailAddress}
                            </Text>
                            <Text>
                              Amount: {ctc.ownershipAmount}
                            </Text>
                            <Text>
                              ContactID: {ctc.businessContactId}
                            </Text>
                          </Card>
                        })
                      } */}
                      
                    </Pane>
                    <Pane display="flex" marginTop={10} marginBottom={20} marginX={12} >
                      <Button
                        type="button"
                        justifyContent="center"
                      >
                        Cancel
                    </Button>
                    </Pane>
                  </Card>

                </Pane>


              </SheetBodyLayout>
            </Form>
          </Formik>
        )}
    </>
  )
}

export default ClearentOwner

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
