import React from 'react'
import { Button as EvergreenButton, ButtonProps } from 'evergreen-ui'

import Icon from './icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends Omit<ButtonProps, 'iconBefore' | 'iconAfter'> {
  iconBefore?: IconProp
  iconAfter?: IconProp
  children?: React.ReactNode
}

const Button = ({ iconBefore, iconAfter, children, ...props }: Props) => {
  let iconColor
  if (props.appearance === 'primary') iconColor = 'white'
  else if (props.appearance === 'minimal') iconColor = props.intent ?? 'primary'
  else iconColor = props.intent ?? 'base'

  return (
    <EvergreenButton type="button" {...props}>
      {iconBefore && <Icon icon={iconBefore} marginRight={8} color={iconColor} opacity="0.7" data-undraggable />}
      {children}
      {iconAfter && <Icon icon={iconAfter} marginLeft={8} color={iconColor} opacity="0.7" data-undraggable />}
    </EvergreenButton>
  )
}

export default Button
