import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import { GET_USER_RECIPIENT_LIST_REPORT } from 'graphql/_user-sheet'
import * as Types from 'types/graphql'
import { Pane, Label, toaster, Table } from 'evergreen-ui'
import { Card, Spinner } from '@pearly/lib'

export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void
    user?: {
        id: string
        firstName: string
        lastName: string
        google: { email: string; role: Types.UserRole; verified: boolean }
        newsletterSubscription: boolean
    },
}

const EmailReport = ({ user }: Props) => {
    const [reportData, setReportData] = React.useState<any>([])
    const { loading, error, data } = useQuery<Types.getUserRecipientListReport>(GET_USER_RECIPIENT_LIST_REPORT, {
        variables: { email: user?.google.email },
    })

    useEffect(() => {
        if (error) toaster.danger(`Unable to fetch report`)
        else if (data && !loading) {
            setReportData(data?.getUserRecipientListReport)
        }
    }, [data])

    return (
        <SheetLayout>
            <Pane gridArea="body" overflow="scroll" background="blueTint">
                <Card backgroundColor="white" elevation={0} margin={16} padding={16}>
                    <Label marginTop={4} marginBottom={4}>
                        Internal
                    </Label>
                    <Table>
                        <Table.Head>
                            <Table.TextHeaderCell>Email ID</Table.TextHeaderCell>
                            <Table.TextHeaderCell>Recipient Field</Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body height={230}>
                            {loading ? (
                                <Spinner marginY={48} />
                            ) : (
                                <>
                                    {reportData
                                        .filter((rd: any) => rd.category === Types.EmailCategoryType.INTERNAL)
                                        .sort((a: any, b: any) => a.emailId - b.emailId)
                                        .map((data: any) => (
                                            <Table.Row key={data.emailId} height={25}>
                                                <Table.TextCell>{data.emailId}</Table.TextCell>
                                                <Table.TextCell isNumber>{data.recipientField ? data.recipientField : '-'}</Table.TextCell>
                                            </Table.Row>
                                        ))
                                    }
                                </>
                            )}
                        </Table.Body>
                    </Table>
                </Card>
                <Card backgroundColor="white" elevation={0} margin={16} padding={16}>
                    <Label marginTop={4} marginBottom={4}>
                        External
                    </Label>
                    <Table>
                        <Table.Head>
                            <Table.TextHeaderCell isNumber>Email ID</Table.TextHeaderCell>
                            <Table.TextHeaderCell>Recipient Field</Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body height={280}>
                            {loading ? (
                                <Spinner marginY={48} />
                            ) : (
                                <>
                                    {reportData
                                        .filter((rd: any) => rd.category === Types.EmailCategoryType.EXTERNAL)
                                        .sort((a: any, b: any) => a.emailId - b.emailId)
                                        .map((data: any) => (
                                            <Table.Row key={data.emailId} height={25}>
                                                <Table.TextCell isNumber>{data.emailId}</Table.TextCell>
                                                <Table.TextCell>{data.recipientField ? data.recipientField : '-'}</Table.TextCell>
                                            </Table.Row>
                                        ))
                                    }
                                </>
                            )}
                        </Table.Body>
                    </Table>
                </Card>
            </Pane>
        </SheetLayout>
    )
}

export default EmailReport

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
