import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { pickBy } from 'lodash'

import { Pane, Heading, Label, toaster, Checkbox } from 'evergreen-ui'
import { Button, Card, CardHeader, Switch, TextInput, TextInputField, FormError, InfoTooltip, Spinner } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { useQuery } from '@apollo/react-hooks'
import { GET_VENDORS } from 'graphql/_vendor-table'

export type Props = {
  plan: Types.PlanSheet_plan
  plans: Types.Plan[]
  update: [Types.UpdatePlanProp, Types.UpdatePlanStatusProp]
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const PlanFees = ({ plan, plans, update: [updatePlan, updatePlanStatus], dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')
  const [perioState, setPerioState] = useState(
    plan.perio === null ? 0 : plan.perio ? 1 : (-1 as string | number | boolean)
  )
  const [allPlans, setAllPlans] = useState<any[]>([])

  const initialValues = {
    name: plan.name,
    minAgeToggle: !!plan.minAge,
    minAge: plan.minAge ?? '',
    maxAgeToggle: !!plan.maxAge,
    maxAge: plan.maxAge ?? '',
    annualPriceActive: plan.annualPriceActive,
    annualPrice: plan.annualPrice ?? '',
    monthlyPriceActive: plan.monthlyPriceActive,
    monthlyPrice: plan.monthlyPrice ?? '',
    dependentDiscountToggle: !!plan.dependentDiscount,
    dependentDiscount: plan.dependentDiscount ?? '',
    setupFee: plan.setupFee ?? '',
    isDefault: plan.isDefault,
    vendorPrice: 0
  }
  useEffect(() => {
    setAllPlans(plans.filter((item) => item.id !== plan.id))
  },[plans])

  // const { loading, error, data: vendors } = useQuery<Types.GetVendors>(GET_VENDORS)
  const [selectVendor, setSelectVendor] = useState<any[]>([])

  useEffect(() => {
    console.log('selectVendor', selectVendor)
  }, [selectVendor])

  return (
    <Formik
      initialValues={initialValues}
      validate={args => {
        if (!dirty && args !== initialValues) setDirty(true)
      }}
      onSubmit={args => {
        // let searchPlanName = plans.find(x => x.name === args.name)?.name
        // if (searchPlanName)
        //   return toaster.warning('Plan is already available')

        if (allPlans.find((plan) => plan.name.toLowerCase() === args.name.toLowerCase())) {
          return toaster.warning('Plan name already exists. Please choose a different plan name.')
        }
        if (!args.monthlyPriceActive)
          return toaster.warning('Please set Membership Dues ($)')

        const submitArgs = pickBy(args, arg => arg !== '')

        showConfirmDialog({
          body: 'Are you sure you want to save this plan?',
          confirm: () => {
            updatePlan({
              variables: {
                id: plan.id,
                perio: perioState === 1 ? true : perioState === -1 ? false : null,
                ...submitArgs
              }
            })
          }
        })
      }}
      validationSchema={Yup.object({
        name: Yup.string().required('Plan name is required'),
        annualPrice: Yup.mixed().when('annualPriceActive', {
          is: true,
          then: Yup.number().required('Annual price is empty')
        }),
        minAge: Yup.number().when('minAgeToggle', { is: true, then: Yup.number().required('Minimum age is empty') }),
        maxAge: Yup.number().when('maxAgeToggle', { is: true, then: Yup.number().required('Maximum age is empty') }),
        monthlyPrice: Yup.mixed().when('monthlyPriceActive', {
          is: true,
          then: Yup.number().required('Monthly price is empty')
        }),
        dependentDiscount: Yup.mixed().when('dependentDiscountToggle', {
          is: true,
          then: Yup.number()
            .required('Dependent discount is empty')
            .min(0, 'Discount must be a percentage out of 100')
            .max(100, 'Discount must be a percentage out of 100')
        })
      })}
    >
      {formProps => (
        <Form style={{ height: '100%', width: '100%' }} onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
          <SheetBodyLayout>
            <Pane gridArea="body" background="blueTint" overflow="scroll">
              <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader>
                  <Heading size={500}>Plan Details</Heading>
                </CardHeader>
                <Pane padding={24}>
                  <TextInputField name="name" label="Plan Name" placeholder="Plan Name" height={40} width="450px" maxlength={64} />
                  <Pane display="flex">
                    {/* <Pane marginRight={16} flex={1}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Minimum Age
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="minAgeToggle" marginBottom={0} />
                        <TextInput
                          name="minAge"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.minAgeToggle ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane>
                    <Pane marginRight={16} flex={1}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Maximum Age
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="maxAgeToggle" marginBottom={0} />
                        <TextInput
                          name="maxAge"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.maxAgeToggle ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane> */}
                    {/* <Pane flex={1.4}>
                      <Label marginBottom={9} alignSelf="flex-start" display="block">
                        Perio Eligibility
                        <InfoTooltip
                          position="top"
                          content="Set whether patients with periodontal disease, gingivitis, or gum disease are eligible for the plan."
                        />
                      </Label>
                      <SegmentedControl
                        value={perioState}
                        onChange={value => setPerioState(value)}
                        options={[
                          { label: 'Exclude', value: -1 },
                          { label: 'Require', value: 1 },
                          { label: 'Allow', value: 0 }
                        ]}
                      />
                    </Pane> */}
                  </Pane>
                </Pane>
              </Card>

              <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader>
                  <Heading size={500}>Pricing</Heading>
                </CardHeader>
                <Pane padding={24}>
                  <Pane display="flex">
                    {/* <Pane marginRight={16} flex={1}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Set up Fee ($)
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="setupFee" marginBottom={0} />
                        <TextInput
                          name="setupFee"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.setupFee ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane> */}
                    <Pane marginRight={16} flex={1}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Membership Dues ($)
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="monthlyPriceActive" marginBottom={0} />
                        <TextInput
                          name="monthlyPrice"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.monthlyPriceActive ? 'visible' : 'hidden'}
                        />
                      </Pane>
                      {/* <Pane marginTop={10}>
                        <TextInput
                          name="monthStartDate"
                          type="date"
                          height={40}
                          marginBottom={0}
                          visibility={formProps.values.monthlyPriceActive ? 'visible' : 'hidden'}
                        />
                      </Pane> */}
                    </Pane>
                    {/* <Pane flex={1.4}>
                      <Label marginBottom={4} alignSelf="flex-start" display="block">
                        Uses Fee (%)
                      </Label>
                      <Pane display="flex" alignItems="center">
                        <Switch name="dependentDiscountToggle" marginBottom={0} />
                        <TextInput
                          name="dependentDiscount"
                          type="number"
                          height={40}
                          width={64}
                          marginBottom={0}
                          marginLeft={16}
                          visibility={formProps.values.dependentDiscountToggle ? 'visible' : 'hidden'}
                        />
                      </Pane>
                    </Pane> */}
                  </Pane>
                </Pane>
              </Card>
              {/* <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                <CardHeader>
                  <Heading size={500}>Vendors Pricing</Heading>
                </CardHeader>
                <Pane padding={24}>
                  <Pane display="flex">
                    <Pane marginRight={16} flex={1}>
                      {
                        !loading ? vendors && vendors?.vendors?.map((item: Types.GetVendors_vendors) => {

                          return (
                            <>
                              <Pane display="flex" alignItems="center">
                                <Checkbox
                                  marginBottom={4}
                                  marginTop={0}
                                  marginRight={10}
                                  checked={selectVendor.includes(item.vendor)}
                                  onChange={e => {
                                    if (!selectVendor.includes(item.vendor)) {
                                      setSelectVendor([...selectVendor, item.vendor])
                                    } else {
                                      setSelectVendor(selectVendor.filter((item2:any) => item2 !== item.vendor))
                                    }
                                  }
                                  }
                                />
                                <Label marginBottom={4} alignSelf="flex-start" display="block">
                                  {item.vendor}
                                </Label>
                              </Pane>
                              {selectVendor.includes(item.vendor) && <TextInput
                                name="vendorPrice"
                                type="number"
                                height={40}
                                width={64}
                                marginBottom={0}
                                marginLeft={16}
                              />}
                            </>
                          )
                        })
                          :
                          <Pane display="flex" alignItems="center" height="100%" background="white">
                            <Spinner delay={0} />
                          </Pane>
                      }
                    </Pane>
                  </Pane>
                </Pane>
              </Card> */}
            </Pane>
            <Pane
              gridArea="footer"
              elevation={0}
              padding={16}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormError marginTop={0} max={2} />

              <Button
                isLoading={updatePlanStatus.called && updatePlanStatus.loading}
                type="submit"
                appearance="primary"
                height={48}
                justifyContent="center"
              >
                Save
              </Button>
            </Pane>
          </SheetBodyLayout>
        </Form>
      )}
    </Formik>
  )
}

export default PlanFees

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
