import gql from 'graphql-tag'

const fragments = {
  dentistFields: gql`
    fragment dentistFields on Dentist {
      id
      salutation
      name
      degree
    }
  `
}

export const UPSERT_DENTIST = gql`
  mutation UpsertDentist($id: ID, $salutation: Salutation!, $name: String!, $degree: Degree!) {
    upsertDentist(id: $id, salutation: $salutation, name: $name, degree: $degree) {
      ...dentistFields
    }
  }
  ${fragments.dentistFields}
`

export const DELETE_DENTIST = gql`
  mutation DeleteDentist($id: ID!) {
    deleteDentist(id: $id) {
      id
    }
  }
`

export const GET_DENTISTS = gql`
  query GetDentists {
    dentists {
      ...dentistFields
    }
  }
  ${fragments.dentistFields}
`
