import React, { useState, useEffect } from 'react'

import { ApolloClient } from 'apollo-client'
import { useQuery } from '@apollo/react-hooks'
import { GET_SERVICE_PLANS } from './graphql/_service-agreement'
import * as Types from './types'
import moment from 'moment'

import { Pane, Heading, PaneProps } from 'evergreen-ui'

import Button from './button'
import Card from './card'
import Spinner from './spinner'
import { colors } from './utility/theme'

import ServiceContractSheet from './_sheets/service-contract-sheet'

export interface Props extends PaneProps {
  apolloClient: ApolloClient<any>
  accountId?: string
  selectPlan?: (plan: Types.ServicePlans_servicePlans, term: Types.PlanTerm) => void
  selectedPlanId?: string,
  platformTarget?: string
}

const ServicePlanList = ({
  accountId,
  apolloClient,
  selectedPlanId,
  selectPlan,
  platformTarget,
  ...props
}: Props) => {
  const [isContractSheetShown, setIsContractSheetShown] = useState(false)
  const [planId, setPlanId] = useState('')

  // refetch every time
  const { loading, error, data } = useQuery<Types.ServicePlans>(GET_SERVICE_PLANS, {
    fetchPolicy: 'network-only',
    client: apolloClient
  })

  const annualPlans = data?.servicePlans
    .filter(plan => plan.annualPriceActive)
    .sort((a, b) => a.annualPrice! - b.annualPrice!)
  const monthlyPlans = data?.servicePlans
    .filter(plan => plan.monthlyPriceActive)
    .sort((a, b) => a.monthlyPrice! - b.monthlyPrice!)

  const hasAnnualAndMonthly = !!(annualPlans?.length && monthlyPlans?.length)
  const [term, setTerm] = useState<Types.PlanTerm>()
  const shownPlans = hasAnnualAndMonthly
    ? term === Types.PlanTerm.ANNUAL
      ? annualPlans
      : monthlyPlans
    : data?.servicePlans

  // Effect runs once plans are loaded, sets initial term based on presence of annualPlans
  useEffect(() => {
    // Redeclare annual plans to avoid dependency warning
    const annualPlans = data?.servicePlans
      .filter(plan => plan.annualPriceActive)
      .sort((a, b) => a.annualPrice! - b.annualPrice!)

    if (annualPlans) {
      setTerm(annualPlans.length ? Types.PlanTerm.ANNUAL : Types.PlanTerm.MONTHLY)
    }
  }, [data])

  var tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
  var nextyear = moment().add(1, 'years').format('YYYY-MM-DD')

  return loading || error || !data || !term ? (
    <Pane height={225} display="flex" alignItems="center">
      <Spinner />
    </Pane>
  ) : (
      <>
          <ServiceContractSheet
            isShown={isContractSheetShown}
            setIsShown={setIsContractSheetShown}
            planId={planId}
            planTerm={Types.PlanTerm.MONTHLY}
            cancelled={false} 
            effectiveDate={tomorrow}
            endDate={nextyear}
            serviceStatus={'ACTIVE'}
            setupFee={0}
            serviceFee={0}
            isSubscription= {false}
            isLoading={false}
            updateStatus={() =>{}}
          />
        <Pane overflow="scroll" padding={16} {...props}>
          {!shownPlans?.length ? (
            <Heading size={500} flexShrink={0} textAlign="center" paddingY={16} paddingX={40}>
              There are no qualifying service plans.
            </Heading>
          ) : (
              <Pane
                display="flex"
                flexDirection={'row'}
                justifyContent="center"
                flexWrap={'wrap'}
              >
                {shownPlans.map((plan, i) => {
                  return (
                    <ServicePlanCard
                      key={i}
                      plan={plan}
                      term={term}
                      margin={12}
                      marginBottom={'auto'}
                      onClick={() => selectPlan && selectPlan(plan, term)}
                      showContractSheet={() => {
                        setPlanId(plan.id)
                        setIsContractSheetShown(true)
                      }}
                      borderColor={plan.id === selectedPlanId ? colors.blue.base : 'transparent'}
                      borderStyle="solid"
                      borderWidth="2px"
                    />
                  )
                })}
              </Pane>
            )}
        </Pane>
      </>
    )
}

export default ServicePlanList

interface ServicePlanCardProps extends PaneProps {
  plan: Types.ServicePlans_servicePlans
  term: Types.PlanTerm
  showContractSheet: () => void
}


const ServicePlanCard = ({ plan, term, showContractSheet, ...props }: ServicePlanCardProps) => {
  return (
    // Enrollment Card
    <Card alignItems="center" paddingTop={24} elevation={1} hoverElevation={2} {...props} cursor="pointer">
      <Heading size={500} marginBottom={8} marginLeft={2}>
        {plan.name}
      </Heading>

      <Heading size={500} marginBottom={8} marginLeft={2}>
        <ShownPrice term={term} plan={plan} />
      </Heading>

      <Button
        appearance="minimal"
        type="button"
        height={24}
        marginLeft={8}
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          showContractSheet()
        }}
      >
        More Details
      </Button>
    </Card>
  )
}

const ShownPrice = ({
  term,
  plan
}: {
  term: Types.PlanTerm
  plan: {
    annualPrice: number | null
    monthlyPrice: number | null
  }
}) => {
  const price = ((term === Types.PlanTerm.ANNUAL) ? plan.annualPrice : plan.monthlyPrice) ?? 0

  return (
    <>
      <Pane display="flex" alignItems="flex-end">
        <Heading size={600}>${Math.round(price)}</Heading>
        <Heading size={500} marginBottom={2} marginLeft={2}>
          {` / ${term === Types.PlanTerm.ANNUAL ? 'yr' : 'mo'}`}
        </Heading>
      </Pane>
    </>
  )
}
