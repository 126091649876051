import gql from 'graphql-tag'

export const INVITE_MEMBER = gql`
  mutation InviteMember($emailOrPhone: String!) {
    inviteMember(emailOrPhone: $emailOrPhone)
  }
`

export const RESEND_MEMBER_WELCOME_EMAIL = gql`
  mutation resendMemberWelcomeEmail($email: String!) {
    resendMemberWelcomeEmail(email: $email)
  }
`
