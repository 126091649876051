import React, { useState } from 'react'
import * as Types from '../types'
import styled from 'styled-components/macro'

import { Switch, Route } from 'react-router-dom'
import { SideSheet, Pane, Heading } from 'evergreen-ui'

import Button from '../button'
import CardHeader from '../card-header'
import ProgressBar from '../progress-bar'
import SendInviteDialog from '../_dialogs/send-invite-dialog'

import EnrollMember from '../enroll-member'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  parentId?: string
  initialStatus?: Types.PlanSubscriptionStatus
  memberApp?: boolean
}

const steps_primary = [
  { path: 'start', component: EnrollMember.Start, icon: ['far', 'user'] },
  { path: 'details', component: EnrollMember.Details, icon: ['far', 'user'] },
  { path: 'plan', component: EnrollMember.Plan, icon: ['far', 'files-medical'] },
  { path: 'payment', component: EnrollMember.Payment, icon: ['far', 'credit-card'] },
  { path: 'complete', component: EnrollMember.Complete, icon: ['far', 'user'] }
]
const steps_dependent = [
  { path: 'details', component: EnrollMember.Details, icon: ['far', 'user'] },
  { path: 'plan', component: EnrollMember.Plan, icon: ['far', 'files-medical'] },
  { path: 'payment', component: EnrollMember.Payment, icon: ['far', 'credit-card'] },
  { path: 'complete', component: EnrollMember.Complete, icon: ['far', 'user'] }
]

const EnrollMemberSheet = ({ isShown, setIsShown, parentId, initialStatus, memberApp }: Props) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [showSendInviteDialog, setShowSendInviteDialog] = useState(false)

  const [enrollmentState, setEnrollmentState] = useState<Types.EnrollmentState>({
    initialStatus: initialStatus,
    enforce_credit: !!parentId // enforce when enrolling dependent by a parent
  })

  const steps = !!parentId?steps_dependent:steps_primary

  const memberTitle = initialStatus?`${initialStatus} Member`:(parentId ? 'Dependent' : 'New Member')
  return (
    <>
      <SendInviteDialog isShown={showSendInviteDialog} setIsShown={setShowSendInviteDialog} />
      <SideSheet
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        width={400}
        shouldCloseOnOverlayClick= {false}
        containerProps={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        <SheetLayout>
          <CardHeader gridArea="header" justifyContent="space-between">
            <Heading size={600}>Enroll {memberTitle}</Heading>
            {!parentId && (
              <Button appearance="minimal" onClick={() => setShowSendInviteDialog(true)}>
                Send Invite Instead
              </Button>
            )}
          </CardHeader>
          <Pane
            gridArea="progress-bar"
            padding={24}
            paddingBottom={20}
            background="blueTint"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <ProgressBar
              // Adjust to skip second step on progress bar
              index={currentStep > 0 ? currentStep - 1 : 0}
              steps={[steps[0], ...steps.slice(2, steps.length - 1)].map(step => ({ icon: step.icon as IconProp }))}
            />
          </Pane>

          <Pane gridArea="body" display="flex" flexDirection="column" alignItems="center" overflow="hidden">
            <Switch location={{ pathname: steps[currentStep].path, search: '', state: '', hash: '' }}>
              {steps.map((step, i) => (
                <Route key={i} exact path={step.path} strict>
                  <step.component
                    prev={() => {
                      i > 0 && setCurrentStep(i - 1)
                    }}
                    next={() => {
                      i + 1 < steps.length ? setCurrentStep(i + 1) : setIsShown(false)
                    }}
                    enrollmentState={enrollmentState}
                    updateEnrollmentState={(updatedState: Types.EnrollmentState) =>
                      setEnrollmentState({ ...enrollmentState, ...updatedState })
                    }
                    restart={() => {
                      setEnrollmentState({
                        enforce_credit: !!parentId
                      })
                      setCurrentStep(0)
                    }}
                    parentId={parentId}
                    memberApp={memberApp}
                  />
                </Route>
              ))}
            </Switch>
          </Pane>
        </SheetLayout>
      </SideSheet>
    </>
  )
}

export default EnrollMemberSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 100%;
  grid-template-areas:
    'header'
    'progress-bar'
    'body';
  align-items: stretch;
`
