import EnrollMemberStart from './enroll-member-start'
import EnrollMemberDetails from './enroll-member-details'
import EnrollMemberPlan from './enroll-member-plan'
import EnrollMemberPayment from './enroll-member-payment'
import EnrollMemberComplete from './enroll-member-complete'

export default {
  Start: EnrollMemberStart,
  Details: EnrollMemberDetails,
  Plan: EnrollMemberPlan,
  Payment: EnrollMemberPayment,
  Complete: EnrollMemberComplete
}
