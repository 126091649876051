import React from 'react'

import { Pane, Label } from 'evergreen-ui'
import DoubleTextInput from '../double-text-input'
import TextInputField from '../text-input-field'
import Switch from '../switch'

export type Props = {
  provider?: boolean
}

const MemberDetailsFields = ({ provider = false }: Props) => (
  <>
    <Label marginBottom={4} alignSelf="flex-start">
      Name
    </Label>
    <DoubleTextInput half="top" name="firstName" width="100%" placeholder="First Name" autoFocus />
    <DoubleTextInput half="bottom" name="lastName" placeholder="Last Name" width="100%" />
    <TextInputField name="phone" label="Mobile Phone" placeholder="(805) 123-4567" width="100%" />
    <Label alignSelf="flex-start">Date of Birth</Label>
    <Pane display="flex" justifyContent="space-between">
      <TextInputField
        name="dobMonth"
        type="number"
        flex={1}
        placeholder="MM"
        marginRight={16}
        textAlign="center"
        hint="Month"
      />
      <TextInputField
        name="dobDay"
        type="number"
        flex={1}
        placeholder="DD"
        marginRight={16}
        textAlign="center"
        hint="Day"
      />
      <TextInputField name="dobYear" type="number" flex={2} placeholder="YYYY" textAlign="center" hint="Year" />
    </Pane>
    <Pane display="flex" alignItems="center">
      <Label alignSelf="flex-start" marginRight={8}>
        {provider ? 'Has the member' : 'Have you'} been diagnosed with periodontal disease, gingivitis, or gum disease?
      </Label>
      <Switch name="perio" height={24} marginBottom={0} />
    </Pane>
  </>
)

export default MemberDetailsFields
